import Base from '../ly-base/ly-base.js';
import tmpl from './template.mustache';
import toastTmpl from './toast.mustache';

class LyToastSpawner extends Base {
  constructor() {
    super();
    this._delay = 0;
    this._duration = 3000;
    this._message = '';
  }

  get tmpl() {
    return tmpl;
  }

  get DOM(){
    return {
      container: this.querySelector('.ly-toast-container'),
      newToast: this.querySelector('#' + ['ly-toast', this.id].join('-'))
    };
  }

  get message(){
    return this.getAttribute('ly-message') || this._message;
  }

  get duration(){
    return this._duration;
  }

  get delay(){
    return this._delay;
  }

  get oldestToast(){
    return this.DOM.container.firstElementChild;
  }

  static get observedAttributes() { return ['ly-id']; }
  attributeChangedCallback(name, oldValue, newValue) {
    switch (name) {
      case 'ly-id':
        if (oldValue !== newValue && (newValue !== "0")) {
          setTimeout(() => { this.show(); }, this.delay)
          setTimeout(() => { this.hide(); }, this.duration)
        }
    }
  }

  show() {
    this.DOM.container.innerHTML += toastTmpl.render(this.toastProperties);
    this.transitions(this.DOM.newToast).enter();
  }

  get toastProperties(){
    return {
      id: this.id,
      message: this.message
    };
  }

  hide() {
    this.transitions(this.oldestToast).leave(this.afterLeave.bind(this));
  }

  afterLeave() {
    this.DOM.container.removeChild(this.oldestToast);
  }
}

window.customElements.define('ly-toast-spawner', LyToastSpawner);
