
//https://gist.github.com/kiennt2/c9a489369562c424c793b8883b98802e
export default function Linkify(inputText, className='') {
  //URLs starting with http://, https://, or ftp://
  var replacePattern1 =
    /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;

  var replacedText =
    inputText.replace(
      replacePattern1, '<p class="truncate" style="max-width:30ch"><ly-link ly-href="$1" ly-target="_blank">$1</ly-link></p>'
    );

  //URLs starting with www.
  //(without // before it, or it'd re-link the ones done above)
  var replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  var replacedText =
    replacedText.replace(
      replacePattern2,
      '$1<p class="truncate" style="max-width:30ch"><ly-link ly-href="http://$2" ly-target="_blank">$2</ly-link></p>'
    );

  //Change email addresses to mailto:: links
  var replacePattern3 =
    /(([a-zA-Z0-9_\-\.]+)@[a-zA-Z_]+?(?:\.[a-zA-Z]{2,6}))+/gim;
  var replacedText =
    replacedText.replace(
      replacePattern3,
      '<ly-link ly-href="mailto:$1" ly-target="_blank">$1</ly-link>'
    );

  return replacedText
}
