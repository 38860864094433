import Base from '../ly-base/ly-base.js';
import tmpl from './template.mustache';
import './ly-input.css'

export default class LyInput extends Base {

  constructor() {
    super();
    this._inputType='text';
    this._autocapitalize = null;
    this._autocorrect = null;
    this._spellcheck = null;
    this._autocomplete = null;
    this._ariaOwns = null;
    this._ariaAutoComplete = null;
    this._placeholder = null;
  }

  connectedCallback() {
    if (this._rendered) {
      return;
    }
    super.connectedCallback();
    this._addListeners();

    this.moveCursorToEnd();

  }

  _addListeners() {
    this.addEventListener("input", this._handleInput);
    this.addEventListener("focusout", this._handleFocusOut);
    this.DOM.input.addEventListener("focus", this._handleFocus);
  }

  _handleInput(e) {
    e.preventDefault();
    e.stopPropagation();
    e.target.dispatchEvent(
      new Event('ly-input-input', {bubbles: true})
    );
  }

  _handleFocusOut(e) {
    e.preventDefault();
    e.stopPropagation();
    e.target.dispatchEvent(
      new Event('ly-input-focusout', {bubbles: true})
    );
  }

  _handleFocus(e) {
    e.preventDefault();
    e.stopPropagation();
    e.target.dispatchEvent(
      new Event('ly-input-focus', {bubbles: true})
    );
  }

  disconnectedCallback() {
    this.removeEventListener('input', this._handleInput);
    this.removeEventListener('focusout', this._handleFocusOut);
    this.DOM.input.removeEventListener('focus', this._handleFocus);
  }

  moveCursorToEnd() {
    if (this.value) {
      const input = this.DOM.input;

      //setSelectionRange is only supported for:
      //inputs of type text, search, password, tel, and url
      //google setSelectionRange and input types
      if (['text', 'search', 'password', 'tel', 'url'].includes(input.type)) {
        input.selectionStart = input.selectionEnd = this.value.length;
      }
    }
  }

  static get observedAttributes() {
    return ['ly-type', 'ly-has-error', 'ly-value'];
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (this._rendered !== true) return;
    switch (name) {
      case 'ly-type':
        if (newValue !== oldValue) {
          this._rerender();
        }
        break;
      case 'ly-has-error':
        if (newValue !== oldValue) {
          this._rerender();
        }
        break;
      case 'ly-value':
        if ((newValue !== oldValue) && (newValue === '')) {
          this._rerender();
          this.DOM.input.focus();
        }
      default:
    }
  }

  get DOM(){
    return {
      input: this.querySelector('input') || this.querySelector('textarea')
    };
  }

  get tmpl() {
    return tmpl;
  }

  get properties() {
    return {
      autocapitalize: this.autocapitalize,
      autocorrect: this.autocorrect,
      spellcheck: this.spellcheck,
      autocomplete: this.autocomplete,
      ariaOwns: this.ariaOwns,
      ariaAutoComplete: this.ariaAutoComplete,
      role: this.role,
      placeholder: this.placeholder
    };
  }

  get autocapitalize() {
    return this.getAttribute('ly-autocapitalize') || this._autocapitalize;
  }

  get autocorrect() {
    return this.getAttribute('ly-autocorrect') || this._autocorrect;
  }

  get spellcheck() {
    return this.getAttribute('ly-spellcheck') || this._spellcheck;
  }

  get autocomplete() {
    return this.getAttribute('ly-autocomplete') || this._autocomplete;
  }

  get placeholder() {
    return this.getAttribute('ly-placeholder') || this._placeholder;
  }

  get ariaOwns() {
    return this.getAttribute('ly-aria-owns') || this._ariaOwns;
  }

  get ariaAutoComplete() {
    return this.getAttribute('ly-aria-autocomplete') || this._ariaComplete;
  }

  get ariaDescribedby(){
    const res = [];
    const val =
      this.getAttribute('ly-aria-describedby') || this._ariaDescribedby;
    if (val) {
      res.push(val)
    }

    if (this.hasError) {
      res.push(`${this.id}-error-text`)
    }

    return ((res.length > 0) ?  res.join(' ') :  null);
  }
}

window.customElements.define('ly-input', LyInput);
