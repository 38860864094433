import Base from '../ly-base/ly-base.js';
import tmpl from './template.mustache';
import './ly-button.css'

export default class LyButton extends Base {
  constructor() {
    super();
    this._inputType = 'button';
    this._clickEventType = 'ly-button-click'
  }

  connectedCallback() {
    super.connectedCallback();


    this.addEventListeners();
  }

  disconnectedCallback() {
    this.removeEventListeners();
  }

  addEventListeners() {
    this.addEventListener("click", this.clickHandler.bind(this));
  }

  removeEventListeners() {
    this.removeEventListener('click', this.clickHandler);
  }

  get clickEventType(){
    return this.getAttribute('ly-click-event-type') || this._clickEventType;
  }

  clickHandler(e) {
    e.preventDefault();

    this.DOM.button.dispatchEvent(
      new Event(this.clickEventType, {bubbles: true})
    );
  }

  get DOM(){
    return {
      button: this.querySelector('button')
    };
  }

  get tmpl() {
    return tmpl;
  }

}

customElements.define("ly-button", LyButton);
