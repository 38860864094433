import Base from '../ly-base/ly-base.js';
import tmpl from './template.mustache';

class LySlideoverBtn extends Base {

  constructor() {
    super();
    this._height = 'h-5'
  }

  get tmpl() {
    return tmpl;
  }

  get handlers() {
    return {
      'handleClick': this.handleClick
    }
  }

  get height(){
    return this.getAttribute('ly-height') || this._height;
  }

  get properties() {
    return {
      'height': this.height
    }
  }
  handleClick(e) {
    const content = this.getAttribute('ly-content');
    const slideover = document.querySelector('ly-slideover')
    slideover.setAttribute('ly-content', content);
    slideover.setAttribute('ly-open', '');
  }
}

window.customElements.define('ly-slideover-btn', LySlideoverBtn);
