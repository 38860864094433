import LyEventBinder from './ly-event-binder.js';
import LyTransitions from './ly-transitions.js';

export default class Base extends HTMLElement {
  constructor() {
    super();
    this._id = Math.round(Math.random() * 10000);
    this._transitions = {};
    this._rendered = false;

    this._className = null;
    this._name = null;
    this._for = null;
    this._label = null;
    this._value = null;
    this._inputId = null;
    this._inputType = null;
    this._info = null;
    this._errorMessage = null;
    this._disabled = false;
    this._checked = false;
    this._ariaHasPopup = false;
    this._ariaLabel = null;
    this._ariaExpanded = false;
    this._ariaControls = null;
    this._ariaLabelledby = null;
    this._ariaDescribedby = null;
    this._role = null;
    this._tabindex = null;
    this._readonly = false;
  }

  connectedCallback() {
    this._render();
  }

  get _baseProperties() {
    return {
      root: this,
      id: this.id,
      className: this.className,
      name: this.name,
      label: this.label,
      for: this.for,
      info: this.info,
      inputType: this.inputType,
      inputId: this.inputId,
      value: this.value,
      disabled: this.disabled,
      error: this.hasError,
      hasError: this.hasError,
      errorMessage: this.errorMessage,
      checked: this.checked,
      ariaHasPopup: this.ariaHasPopup,
      ariaExpanded: this.ariaExpanded,
      ariaInvalid: this.ariaInvalid,
      ariaControls: this.ariaControls,
      ariaLabelledby: this.ariaLabelledby,
      ariaDescribedby: this.ariaDescribedby,
      role: this.role,
      tabindex: this.tabindex,
      readonly: this.readonly
    };
  }


  get className() {
    return this.getAttribute('class') ||
      this.getAttribute('ly-class') ||
      this._className;
  }

  get name() {
    return this.getAttribute('name') ||
      this.getAttribute('ly-name') ||
      this._name;
  }

  get label() {
    return this.getAttribute('label') ||
      this.getAttribute('ly-label') ||
      this._label;
  }

  get for() {
    return this.getAttribute('for') ||
      this.getAttribute('ly-for') ||
      this._for;
  }

  get info() {
    return this.getAttribute('info') ||
      this.getAttribute('ly-info') ||
      this._info;
  }

  get value() {
    return this.getAttribute('value') ||
      this.getAttribute('ly-value') ||
      this._value;
  }

  get disabled() {
    return this.getAttribute('disabled') ||
      this.getAttribute('ly-disabled') == "true" ||
      this._disabled;
  }

  get checked() {
    const val = this.getAttribute('checked') ||
      this.getAttribute('ly-checked') ||
      this._checked;
    return (val === "true" || val === true);
  }

  get id() {
    this.getAttribute('ly-id') ||
    this._id;
  }

  get inputId() {
    return this.getAttribute('input-id') ||
      this.getAttribute('ly-input-id') ||
      this._inputId;
  }

  get inputType() {
    return this.getAttribute('type') ||
      this.getAttribute('ly-type') ||
      this.getAttribute('ly-input-type') ||
      this._inputType;
  }

  get error() {
    this.hasError();
  }

  get hasError() {
    return (this.getAttribute('ly-has-error') === "true");
  }

  get errorMessage(){
    return this.getAttribute('ly-error-message')|| this._errorMessage;
  }

  get ariaHasPopup(){
    return this.getAttribute('ly-aria-haspopup') || this._ariaHasPopup;
  }

  get ariaLabel(){
    return this.getAttribute('ly-aria-label') || this._ariaLabel;
  }

  get ariaLabelledby(){
    return this.getAttribute('ly-aria-labelledby') || this._ariaLabelledby;
  }

  get ariaDescribedby(){
    return this.getAttribute('ly-aria-describedby') || this._ariaDescribedby;
  }

  get ariaExpanded(){
    return this.getAttribute('ly-aria-expanded') || this._ariaExpanded;
  }

  get ariaInvalid(){
    return this.getAttribute('ly-aria-invalid') || this.hasError;
  }

  get ariaControls(){
    return this.getAttribute('ly-aria-controls') || this._ariaControls;
  }

  get role() {
    return this.getAttribute('ly-role') || this._role;
  }

  get tabindex() {
    return this.getAttribute('ly-tabindex') || this._tabindex;
  }

  get readonly() {
    return this.getAttribute('ly-readonly') || this._readonly;
  }

  get id() {
    return this.getAttribute('ly-id') || this._id;
  }

  get partials() {
    return {
    }
  }

  get properties() {
    return {
    }
  }

  get handlers() {
    return {
    }
  }

  get keyCodes() {
    return {
      enter: 13,
      esc: 27,
      space: 32,
      tab: 9,
      up: 38,
      down: 40,
      left: 37,
      right: 39,
      shift: 16,
      pageup: 33,
      pagedown: 34,
      end: 35,
      home: 36
    }
  }

  transitions(element) {
    return this._instantiateTransition(element);
  }

  _instantiateTransition(element) {
    const newTransition = new LyTransitions(element);
    this._transitions[name] = newTransition;
    return newTransition;
  }

  get _properties() {
    return Object.assign(this._baseProperties, this.properties)
  }

  _rerender() {
    this._rendered = false;
    this._render();
  }

  _render() {
    if (this._rendered === true) return;

    this._buildComponentDom();
    this._bindEvents();
    this._rendered = true;
  }

  _noRender() {
  }

  _removeChildNodes() {
    this._removeChildNodesForElement(this);
    this._rendered = false;
  }

  _removeChildNodesForElement(element) {
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
  }

  _buildComponentDom() {
    const children = this._appendableDomFragment(this.childNodes);

    this.innerHTML = this._tmplToString;

    const slot = this.querySelector('slot');
    if (!!slot) {
      slot.parentNode.replaceChild(children, slot)
    }
  }

  get _tmplToString() {
    return this.tmpl.render(this._properties, this.partials);
  }

  _bindEvents() {
    const eb = new LyEventBinder(this);
    eb.addEventListeners();
  }

  _appendableDomFragment(nodeList) {
    let fragment = document.createDocumentFragment();
    while(nodeList.length)
        fragment.appendChild(nodeList[0]);
    return fragment;
  }

}
