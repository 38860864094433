import Base from '../ly-base/ly-base.js';
import tmpl from './template.mustache';

export default class LyMenu extends Base {

  connectedCallback() {
    this._menuItems = this.innerHTML;
    super.connectedCallback();
    this.addMenuButtonClickHandler();
    this.addMenuItemKeydownHandler();
    this.addCloseOnOutsideClickHandler();
    this.addMenuItemClickHandler();
  }

  get properties(){
    return {
      menuItems: this.menuItems
    }
  }

  addCloseOnOutsideClickHandler() {
    this._documentEvent = this.outsideClickHandler.bind(this);
    document.addEventListener('click', this._documentEvent);
  }

  removeCloseOnOutsideClickHandler() {
    document.removeEventListener('click', this._documentEvent);
  }

  disconnectedCallback() {
    this.removeCloseOnOutsideClickHandler();
  }

  outsideClickHandler(e) {
    if (!this.contains(e.target) && !this.menuButton.contains(e.target) &&
      this.isOpen) {
      this.close();
    }
  }

  addMenuButtonClickHandler() {
    this.menuButton.addEventListener(
      'click', this.menuButtonClickHandler.bind(this)
    )
  }

  addMenuItemKeydownHandler() {
    this.DOM.menuItems.forEach(item => {
      item.addEventListener('keydown', this.menuItemKeydownHandler.bind(this))
    })
  }

  addMenuItemClickHandler() {
    this.DOM.menuItems.forEach(item => {
      item.addEventListener('click', this.menuItemClickHandler.bind(this))
    })
  }

  menuItemClickHandler(e) {
    this.close();
  }

  menuButtonClickHandler(e) {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  menuItemKeydownHandler(e) {
    switch (e.keyCode) {
      case this.keyCodes.up:
        e.preventDefault();
        this.focusPrevious(e.currentTarget);
        break;
      case this.keyCodes.down:
        e.preventDefault();
        this.focusNext(e.currentTarget);
        break;
      case this.keyCodes.esc:
        this.menuButton.focus();
        this.close();
      case this.keyCodes.tab:
        this.close();
    }
  }

  open() {
    this.transitions(this.DOM.dropdown).enter(this.beforeEnter.bind(this));
    this.focusFirst();
  }

  close() {
    this.transitions(this.DOM.dropdown).leave(this.afterLeave.bind(this));
  }

  focusFirst() {
    this.DOM.menuItems[0].focus();
  }

  focusLast() {
    this.DOM.menuItems[this.DOM.menuItems.length - 1].focus();
  }

  focusNext(currentMenuItem) {
    if (currentMenuItem.nextElementSibling) {
      currentMenuItem.nextElementSibling.focus();
    } else {
      this.focusFirst();
    }
  }

  focusPrevious(currentMenuItem) {
    if (currentMenuItem.previousElementSibling) {
      currentMenuItem.previousElementSibling.focus();
    } else {
      this.focusLast();
    }
  }

  beforeEnter() {
    this.DOM.dropdown.classList.remove('hidden');
    this.menuButton.setAttribute('aria-expanded', 'true');
  }

  afterLeave() {
    this.DOM.dropdown.classList.add('hidden');
    if (this.menuButton) {
      this.menuButton.removeAttribute('aria-expanded');
    }
  }

  get menuItems(){
    return this._menuItems;
  }

  get isOpen(){
    return this.menuButton.hasAttribute('aria-expanded') &&
      this.menuButton.getAttribute('aria-expanded') === 'true';
  }

  get tmpl() {
    return tmpl;
  }

  get menuButton(){
    return document.querySelector(`[aria-controls='${this.id}']` );
  }

  get DOM(){
    return {
      dropdown: this.querySelector('.ly-menu-dropdown'),
      menuItems: this.querySelectorAll("[role=menuitem]")
    };
  }
}

window.customElements.define('ly-menu', LyMenu);
