import * as SentryLib from  '@sentry/browser';
import { Integrations as TracingIntegrations } from "@sentry/tracing";

export default class Sentry {
  constructor(config){
    this._config = config;
  }

  init() {
    SentryLib.init({
      dsn: this._config.sentryDsn,
      release: `wt_front@${this._config.version}`,
      integrations: [new TracingIntegrations.BrowserTracing()],
      tracesSampleRate: 1.0
    });
    SentryLib.setUser({"email": this._config.session.email});
  }
}
