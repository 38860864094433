import LyIcon from '../ly-icon/ly-icon.js';

import alienSvg from './svg/alien.mustache';
import checklistSvg from './svg/checklist.mustache';
import maintenanceSvg from './svg/maintenance.mustache';
import notFoundSvg from './svg/not_found.mustache';
import questionSvg from './svg/question.mustache';
import checkListSvg from './svg/checklist.mustache';


class LySvg extends LyIcon {

  get svgTmpls() {
    return {
      'alien': alienSvg,
      'checklist': checklistSvg,
      'maintenance': maintenanceSvg,
      'question': questionSvg,
      'notfound': notFoundSvg
    }
  }

  get tmpl(){
    return this.svgTmpls[this.name];
  }
}

window.customElements.define('ly-svg', LySvg);
