import Base from '../ly-base/ly-base.js';
import tmpl from './template.mustache';
import Href from './href.js';
import './ly-link.css'

export default class LyLink extends Base {
  constructor(){
    super()
    this._target = null;
    this._icon = null;
  }
  get tmpl() {
    return tmpl;
  }

  get properties() {
    return {
      href: this.href.toString(),
      target: this.target,
      icon: this.icon
    }
  }

  get className() {
    return ['ly-link', super.className].join(' ');
  }

  get href(){
    return new Href(this.getAttribute('ly-href'));
  }

  get target(){
    return this.getAttribute('ly-target') || this._target;
  }

  get icon() {
    return this.getAttribute('ly-icon') || this.deriveIcon() || this._icon;
  }

  deriveIcon() {
    if (this.href.isMailTo) return 'mail';
    if (this.href.isExternalLink) return 'external-link';
    return null;
  }

}

window.customElements.define('ly-link', LyLink);
