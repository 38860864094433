import Base from '../ly-base/ly-base.js';
import tmpl from './template.mustache';
import statsTmpl from './_stats.mustache';
import './ly-task-item.css'

class LyTaskItem extends Base {

  constructor() {
    super();
    this._description = '';
    this._project = '';
    this._due = '';
    this._taskId = '';
    this._annotationCount = null;
  }


  connectedCallback() {
    super.connectedCallback();
    this.attachButtonClickHandlers();
    this.attachTextSelectFriendlyClickHandler();
    this.attachProjectClickHandler();
    this.attachTagClickHandler();
  }

  attachButtonClickHandlers() {
    this.addEventListener('ly-button-click', e => {
      if (e.target.classList.contains('task-edit')) {
        const newEvent =
          new Event('ly-button-task-edit-click', {bubbles: true});

        this.dispatchEvent(newEvent);
      }
      if (e.target.classList.contains('task-view')) {
        const newEvent =
          new Event('ly-button-task-view-click', {bubbles: true});
        console.log('dispatch view click');

        this.dispatchEvent(newEvent);
      }
      if (e.target.classList.contains('task-done')) {
        const newEvent =
          new Event('ly-button-task-done-click', {bubbles: true});

        this.dispatchEvent(newEvent);
      }
      if (e.target.classList.contains('task-delete')) {
        const newEvent =
          new Event('ly-button-task-delete-click', {bubbles: true});

        this.dispatchEvent(newEvent);
      }
    })
  }

  attachTextSelectFriendlyClickHandler() {
    let down, up = null;
    this.DOM.descriptionBlock.onmousedown = () => down = +new Date();
    this.DOM.descriptionBlock.onmouseup = (e) => {
      up = +new Date();
      if ((up - down) < 200) {
        if (this.isIgnorableClick(e.target)) return;

        if (e.target.classList.contains('ly-task-item-tag')) return;

        const newEvent =
          new Event(
            'ly-task-item-nav-click', {bubbles: true}
          );
        this.dispatchEvent(newEvent);
      }
    }
  }

  attachProjectClickHandler() {
    if (!this.DOM.projectLink) return;

    this.DOM.projectLink.addEventListener('click', e => {
      e.preventDefault();
      const newEvent = new Event('ly-task-item-project-click', {bubbles: true});
      this.dispatchEvent(newEvent);
    });
  }

  attachTagClickHandler() {
    if (this.DOM.tagLinks.length === 0) return;

    this.DOM.tagLinks.forEach(tagLink => {
      tagLink.addEventListener('click', e => {
        e.preventDefault();
        const newEvent =
          new CustomEvent(
            'ly-task-item-tag-click',
            {detail: e.target.textContent, bubbles: true}
          )
        this.dispatchEvent(newEvent);
      })
    })

  }

  isIgnorableClick(element) {
    return (element == this.DOM.projectLink) ||
      (element.classList.contains('ly-task-item-tag'));
  }

  get DOM() {
    return {
      descriptionBlock:
        this.querySelector('div.ly-task-item-description-block'),
      projectLink: this.querySelector('a.ly-task-item-project'),
      tagLinks: this.querySelectorAll('a.ly-task-item-tag')
    }
  }

  get properties() {
    return {
      description: this.description,
      project: this.project,
      hasTags: this.hasTags,
      tags: this.tags,
      due: this.due,
      taskId: this.taskId,
      urgency: this.urgency,
      created: this.created,
      priority: this.priority,
      annotationCount: this.annotationCount,
      parentUuid: this.parentUuid
    };
  }

  get tmpl() {
    return tmpl;
  }

  get description() {
    return this.getAttribute('description') || this._description;
  }

  get project() {
    return this.getAttribute('project') || this._project;
  }

  get hasTags(){
    return this.tags.length > 0;
  }

  get tags() {
    return JSON.parse(this.getAttribute('tags')) || [];
  }

  get due() {
    return this.getAttribute('due') || this._due;
  }

  get taskId() {
    return this.getAttribute('task-id') || this._taskId;
  }

  get uuid(){
    return this.getAttribute('uuid');
  }

  get parentUuid(){
    return this.getAttribute('parent-uuid');
  }

  get created() {
    return this.getAttribute('created');
  }

  get now() {
    return new Date(parseInt(this.getAttribute('now')));
  }

  get urgency() {
    return parseFloat(this.getAttribute('urgency')).toPrecision(3).toString();
  }

  get priority(){
    return this.getAttribute('priority');
  }

  get annotationCount(){
    const c = parseInt(this.getAttribute('annotations'));
    return (c > 0) ? c : this._annotationCount;
  }

  get partials() {
    return {
      stats: statsTmpl
    };
  }

}

window.customElements.define('ly-task-item', LyTaskItem);
