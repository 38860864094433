export default class ErrorsRenderer {
  constructor(errTuples, tmpl){
    this._errTuples = errTuples;
    this._tmpl = tmpl;
  }

  toHtmlString() {
    return this._errTuples.flatMap(errTuple => {
      return this._mapErrorsHtml(errTuple);
    }).join('');
  }

  _mapErrorsHtml(errTuple) {
    return errTuple[1].map(errorMessage => {
      return this._renderTmpl(errTuple[0], errorMessage);
    });
  }

  _renderTmpl(key, errorMessage) {
    return this._tmpl.render(
      {inputName: [key, 'input'].join('-'), errorMessage: errorMessage}
    );
  }

}
