//This event binder allows the use of on-click="handleClick" within a template
//So that a handleClick could be defined in anything that uses a button as
//opposed to having to write an event responder. It was previously more useful
//than it is now when "is" type custom elements were used.
export default class LyEventBinder {
  constructor(invoker) {
    this.invoker = invoker;
  }

  addEventListeners() {
    this._bindEvents();
  }

  get _invoker() {
  }

  get _eventTypes() {
    return ['click', 'input', 'focusout'];
  }

  _bindEvents() {
    this._eventTypes.forEach(et => this._bindEventsForEventType(et));
  }

  _bindEventsForEventType(eventType) {
    const elements =
      this.invoker.querySelectorAll(['[on', eventType + ']'].join('-'));
    elements.forEach(element => this._bindEventForElement(eventType, element));
  }

  _bindEventForElement(eventType, element) {
    const elementEventAttribute =
      element.getAttribute(['on', eventType].join('-'));
    const eventHandler = this.invoker.handlers[elementEventAttribute];
    if (!!eventHandler) {
      element.addEventListener(
        eventType,
        this.invoker.handlers[
          elementEventAttribute
        ].bind(this.invoker)
      );
    }
    element.invoker = this.invoker;
  }
}
