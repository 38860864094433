import Base from '../ly-base/ly-base.js';
import tmpl from './template.mustache';
import dnaEditJson from './lottie/dna-loader.json';

class LyLottie extends Base {
  get properties(){
    return {
      lottieJson: this.lottieJson
    };
  }

  get lottieJson(){
    return JSON.stringify(this.lottieJsons[this.name]);
  }

  get lottieJsons(){
    return {
      dnaEdit: dnaEditJson
    };
  }

  get DOM(){
    return {
      lottiePlayer: this.querySelector('lottie-player')
    };
  }

  get tmpl(){
    return tmpl;
  }
}

window.customElements.define('ly-lottie', LyLottie);
