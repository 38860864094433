import Base from '../ly-base/ly-base.js';
import tmpl from './template.mustache';

export default class LyMenuAppHeader extends Base {
  get tmpl() {
    return tmpl;
  }

  connectedCallback() {
    super.connectedCallback();
    this.DOM.syncButton.addEventListener('click', e => {
      this.dispatchEvent(
        new Event('ly-menu-app-header-sync-click', {bubbles: true})
      );
    })
    this.DOM.logoutButton.addEventListener('click', e => {
      this.dispatchEvent(
        new Event('ly-menu-app-header-logout-click', {bubbles: true})
      );
    })
    this.DOM.settingsLink.addEventListener('click', e => {
      this.dispatchEvent(
        new Event('ly-menu-app-header-settings-click', {bubbles: true})
      );
    })
  }

  get DOM(){
    return {
      syncButton: this.querySelector('button.ly-menu-item-sync'),
      logoutButton: this.querySelector('button.ly-menu-item-logout'),
      settingsLink: this.querySelector('button.ly-menu-item-settings'),
      helpLink: this.querySelector('a.ly-menu-item-help')
    };
  }
}

window.customElements.define('ly-menu-app-header', LyMenuAppHeader);
