import Base from '../ly-base/ly-base.js';
import tmpl from './template.mustache';
import errorTmpl from './_error.mustache';

export default class LyInputError extends Base {

  static get observedAttributes() { return ['ly-error-message']; }
  attributeChangedCallback(name, oldValue, newValue) {
    if (this._rendered !== true) return;
    switch (name) {
      case 'ly-error-message':
        if (this.isOpen) {
          this.transition(oldValue, newValue, this._rerender);
        } else {
          this._rerender();
          this.transition(oldValue, newValue);
        }
        break;
      default:
    }
  }

  connectedCallback() {
    super.connectedCallback();
    if (this.errorMessage) {
      this.showErrorMessage();
      this.DOM.errorContainer.classList.remove('opacity-0');
    }
  }

  get errorMessage(){
    return this.getAttribute('ly-error-message');
  }

  get tmpl() {
    return tmpl;
  }

  get DOM(){
    return {
      errorContainer: this.querySelector('.error-container')
    };
  }

  get isOpen(){
    return (!!this.DOM.errorContainer);
  }

  transition(oldValue, newValue) {
    if (!newValue) { this.transitionOut(); return; }

    this.transitionIn();
    return

  }

  transitionIn() {
    this.transitions(this.DOM.errorContainer).enter(
      this.showErrorMessage.bind(this)
    );
  }

  transitionOut() {
    this.transitions(this.DOM.errorContainer).leave(
      this.removeErrorMessage.bind(this)
    );
  }

  showErrorMessage(errorMsg) {
    this.DOM.errorContainer.innerHTML = this.renderErrorTemplate(errorMsg)
  }

  removeErrorMessage() {
    this._removeChildNodesForElement(this.DOM.errorContainer);
  }

  renderErrorTemplate(errorMsg) {
    return errorTmpl.render({errorMessage: this.errorMessage, for: this.for});
  }

  get partials() {
    return {
      error: errorTmpl
    };
  }
}

window.customElements.define('ly-input-error', LyInputError);
