import Base from '../ly-base/ly-base.js';
import tmpl from './template.mustache';
import errorTmpl from './_error.mustache';

export default class LyInputField extends Base {

  constructor() {
    super();
    this._helperText = null;
  }

  static get observedAttributes() { return ['ly-error-message']; }
  attributeChangedCallback(name, oldValue, newValue) {
    if (this._rendered !== true) return;
    switch (name) {
      case 'ly-error-message':
        this.DOM.lyInputError.setAttribute('ly-error-message', newValue);
        break;
      default:
    }
  }

  get tmpl() {
    return tmpl;
  }

  get properties() {
    return {
      helperText: this.helperText
    };
  }

  get DOM(){
    return {
      lyInputError: this.querySelector('ly-input-error')
    };
  }

  get helperText() {
    return this.getAttribute('ly-helper-text') || this._helperText;
  }

  get partials() {
    return {
      error: errorTmpl
    };
  }
}

window.customElements.define('ly-input-field', LyInputField);
