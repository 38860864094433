import Base from '../ly-base/ly-base.js';
import tmpl from './template.mustache';

class LyModal extends Base {

  constructor() {
    super();
  }

  get handlers() {
    return {
      'handleCloseClick': this.handleCloseClick
    }
  }

  get tmpl() {
    return tmpl;
  }

  get DOM() {
    return {
      modal: this.querySelector('#modal'),
      modalOverlay: this.querySelector('#modal-overlay')
    }
  }

  connectedCallback() {
    this._noRender();
  }

  static get observedAttributes() { return ['ly-open']; }
  attributeChangedCallback(name, oldValue, newValue) {
    switch (name) {
      case 'ly-open':
        if (((oldValue === null) && (newValue === 'true')) ||
          ((oldValue === 'false') && (newValue === 'true'))) {
          if (this._rendered) {
            break;
          } else {
            this._render();
            this.transitionIn();
          }
          break;
        }

        if ((oldValue === 'true') && (newValue === 'false')) {
          this.transitionOut();
        }
    }
  }

  showModal() {
  }

  transitionIn() {
    this.transitions(this.DOM.modalOverlay).enter();
    this.transitions(this.DOM.modal).enter();
  }

  transitionOut() {
    this.transitions(this.DOM.modalOverlay).leave(this.afterLeave.bind(this));
    this.transitions(this.DOM.modal).leave(this.afterLeave.bind(this));
  }

  afterLeave() {
    this._removeChildNodes();
  }

  handleCloseClick(e) {
    e.target.dispatchEvent(new Event('ly-modal-close', {bubbles: true}));
  }
}

window.customElements.define('ly-modal', LyModal);
