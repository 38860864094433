import LyIcon from '../ly-icon/ly-icon.js';
import audioSpinnerTmpl from './spinners/_audio.mustache';
import ballTriangleTmpl from './spinners/_ball-triangle.mustache';
import barsTmpl from './spinners/_bars.mustache';
import circlesTmpl from './spinners/_circles.mustache';
import gridTmpl from './spinners/_grid.mustache';
import heartsTmpl from './spinners/_hearts.mustache';
import ovalTmpl from './spinners/_oval.mustache';
import puffTmpl from './spinners/_puff.mustache';
import ringsTmpl from './spinners/_rings.mustache';
import spinningCirclesTmpl from './spinners/_spinning-circles.mustache';
import tailSpinTmpl from './spinners/_tail-spin.mustache';
import threeDotsTmpl from './spinners/_three-dots.mustache';
import indeterminateSpinTmpl from './spinners/_indeterminate-spin.mustache';
import boxTmpl from './spinners/_box.mustache';

class LySpinner extends LyIcon {

  get iconTmpls() {
    return {
      'audio': audioSpinnerTmpl,
      'ball-triangle': ballTriangleTmpl,
      'bars': barsTmpl,
      'circles': circlesTmpl,
      'grid': gridTmpl,
      'hearts': heartsTmpl,
      'oval': ovalTmpl,
      'puff': puffTmpl,
      'rings': ringsTmpl,
      'spinning-circles': spinningCirclesTmpl,
      'tail-spin': tailSpinTmpl,
      'three-dots': threeDotsTmpl,
      'box': boxTmpl,
      'indeterminate-spin': indeterminateSpinTmpl
    };
  }
}

window.customElements.define('ly-spinner', LySpinner);
