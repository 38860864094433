const { Elm } = require('../../../src/Main');
import * as Sentry from  '@sentry/browser';

export default class ElmInit {
  constructor(config){
    this._config = config;
  }

  init(session) {
    const flags =
      {
        session: session,
        uuidSeed: Math.floor(Math.random()*0x0FFFFFFF),
        config: this._config.toString()
      }

    const app = Elm.Main.init({
      flags: flags
    });

    this.setPortSubscribers(app);
    this.setPortSenders(app)
  }

  setPortSubscribers(app) {
    this.subscribeStoreSession(app);
    this.subscribePostSentryMessage(app);
    this.subscribeUpgradeVersion(app);
    this.subscribeGetActiveElement(app);
    this.subscribeFadeYellow(app);
  }

  setPortSenders(app) {
    this.addStorageListener(app);
  }

  addStorageListener(app) {
    window.addEventListener("storage", function(event) {
      if (event.storageArea === localStorage && event.key === "session"
        && app.ports.onSessionChange
      ) {
        app.ports.onSessionChange.send(event.newValue);
      }
    }, false);
  }

  subscribeStoreSession(app) {
    app.ports.storeSession.subscribe(function(session) {
      const email = !!session ? JSON.parse(session).email : 'not logged in';
      Sentry.setUser({"email": email});
      localStorage.session = session;
    });
  }

  subscribePostSentryMessage(app) {
    app.ports.postSentryMessage.subscribe(content => {
      Sentry.setUser({"email": content.email});
      Sentry.setExtra("version", content.version);
      Sentry.captureMessage(content.message, 'debug');
    })
  }

  subscribeUpgradeVersion(app) {
    app.ports.upgradeVersion.subscribe(function() {
      var upgradeText = "A new version of WingTask is available.\n\n"
      upgradeText += "After clicking 'OK' the app will reload to the new "
      upgradeText += "version.\n"
      upgradeText += "Any operation you were just performing completed "
      upgradeText += "successfully."
      alert(upgradeText);
      window.location.reload(true);
    });
  }

  subscribeGetActiveElement(app) {
    app.ports.getActiveElement.subscribe(function() {
      var id = document.activeElement ? document.activeElement.id : null;
      var message = id ? id : 'no active element';
      app.ports.getActiveElementCompleted.send(id);
    });
  }

  subscribeFadeYellow(app) {
    app.ports.fadeYellow.subscribe(uuid => {
      const element = document.getElementById(uuid);
      if (element) {
        const listItemFromTask = element.querySelector('li');
        const listItemFromAnnotation = element;
        const listItem = listItemFromTask || listItemFromAnnotation;

        listItem.addEventListener("animationend", () => {
          listItem.classList.remove('yellow-fade');
        })

        listItem.classList.add('yellow-fade');
      }
    });
  }
}
