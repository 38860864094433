export default function Autocomplete(select, noResults = 'No Results') {
  this.select = select;
  this.container = select.parentElement;
  this.textBox = this.container.querySelector('input')
  this.menu = this.container.querySelector('ul')
  this.chevron = this.container.querySelector('ly-icon')
  this.status =  this.container.querySelector('[role=status]');
  this.noResults = noResults;
  this.setupKeys();
  this.hideSelectBox();
  this.addTextBoxEvents();
  this.addMenuEvents();
  this.addChevronEvents();
};

Autocomplete.prototype.onDocumentClick = function(e) {
  if(!this.container.contains(e.target)) {
    this.hideMenu();
    this.removeTextBoxFocus();
  }
};

Autocomplete.prototype.setupKeys = function() {
  this.keys = {
    enter: 13,
    esc: 27,
    space: 32,
    up: 38,
    down: 40,
    tab: 9,
    left: 37,
    right: 39,
    shift: 16
   };
};

Autocomplete.prototype.onTextBoxFocus = function() {
  this.textBox.classList.add('autocomplete-isFocused');
};

Autocomplete.prototype.removeTextBoxFocus = function() {
  this.textBox.classList.remove('autocomplete-isFocused');
};

Autocomplete.prototype.onTextBoxClick = function(e) {
  this.clearOptions();
  var options = this.getAllOptions();
  this.buildMenu(options);
  this.updateStatus(options.length);
  this.showMenu();
  if(typeof e.currentTarget.select === 'function') {
    e.currentTarget.select();
  }
};

Autocomplete.prototype.onTextBoxKeyUp = function(e) {
  switch (e.keyCode) {
    case this.keys.esc:
    case this.keys.up:
    case this.keys.left:
    case this.keys.right:
    case this.keys.space:
    case this.keys.enter:
    case this.keys.tab:
    case this.keys.shift:
      // ignore these keys otherwise
      // the menu will show briefly
      break;
    case this.keys.down:
      this.onTextBoxDownPressed(e);
      break;
    default:
      this.onTextBoxType(e);
  }
};

Autocomplete.prototype.onMenuKeyDown = function(e) {
  switch (e.keyCode) {
    case this.keys.up:
      // want to highlight previous option
      this.onOptionUpArrow(e);
      break;
    case this.keys.down:
      // want to highlight next suggestion
      this.onOptionDownArrow(e);
      break;
    case this.keys.enter:
      // want to select the suggestion
      this.onOptionEnter(e);
      break;
    case this.keys.space:
      // want to select the suggestion
      this.onOptionSpace(e);
      break;
    case this.keys.esc:
      // want to hide options
      this.onOptionEscape(e);
      break;
    case this.keys.tab:
      this.hideMenu();
      this.removeTextBoxFocus();
      break;
    default:
      this.textBox.focus();
  }
};

Autocomplete.prototype.onTextBoxType = function(e) {
  if(this.textBox.value.trim().length > 0) {
    var options = this.getOptions(this.textBox.value.trim().toLowerCase());
    this.buildMenu(options);
    this.showMenu();
    this.updateStatus(options.length);
  } else {
    this.hideMenu();
  }
  this.updateSelectBox();
};

Autocomplete.prototype.updateSelectBox = function() {
  var value = this.textBox.value.trim();
  var option = this.getMatchingOption(value);
  if(option) {
    this.select.value = option.value;
  } else {
    this.select.value = '';
  }
};

Autocomplete.prototype.onOptionEscape = function(e) {
  this.clearOptions();
  this.hideMenu();
  this.focusTextBox();
};

Autocomplete.prototype.focusTextBox = function() {
  this.textBox.focus();
};

Autocomplete.prototype.onOptionEnter = function(e) {
  if(this.isOptionSelected()) {
    this.selectActiveOption();
  }
  // we don't want form to submit
  e.preventDefault();
};

Autocomplete.prototype.onOptionSpace = function(e) {
  if(this.isOptionSelected()) {
    this.selectActiveOption();
    // we don't want a space to be added to text box
    e.preventDefault();
  }
};

Autocomplete.prototype.onOptionClick = function(e) {
  var option = e.target;
  this.selectOption(option);
};

Autocomplete.prototype.selectActiveOption = function() {
  var option = this.getActiveOption();
  this.selectOption(option);
};

Autocomplete.prototype.selectOption = function(option) {
  var value = option.getAttribute('data-option-value');
  if (value) {
    this.setValue(value);
  }
  this.hideMenu();
  this.focusTextBox();
};

Autocomplete.prototype.onTextBoxDownPressed = function(e) {
  var option;
  var options;
  var value = this.textBox.value.trim();
  // Empty value or exactly matches an option
  // then show all the options
  if(value.length === 0 || this.isExactMatch(value)) {
    options = this.getAllOptions();
    this.buildMenu(options);
    this.showMenu();
    option = this.getFirstOption();
    this.highlightOption(option);
  } else {
    options = this.getOptions(value);
    if(options.length > 0) {
      this.buildMenu(options);
      this.showMenu();
      option = this.getFirstOption();
      this.highlightOption(option);
    }
  }
};

Autocomplete.prototype.onOptionDownArrow = function(e) {
  var option = this.getNextOption();
  if(option) {
    this.highlightOption(option);
  }
  e.preventDefault();
};

Autocomplete.prototype.onOptionUpArrow = function(e) {
  if(this.isOptionSelected()) {
    var option = this.getPreviousOption();
    if(option) {
      this.highlightOption(option);

    } else {
      this.focusTextBox();
      this.hideMenu();
    }
  }
  e.preventDefault();
};

Autocomplete.prototype.isOptionSelected = function() {
  return this.activeOptionId;
};

Autocomplete.prototype.getActiveOption = function() {
  return document.querySelector('#' + this.activeOptionId);
};

Autocomplete.prototype.getFirstOption = function() {
  return this.menu.querySelectorAll('li')[0];
};

Autocomplete.prototype.getPreviousOption = function() {
  return this.getActiveOption().previousSibling;
};

Autocomplete.prototype.getNextOption = function() {
  return this.getActiveOption().nextSibling;
};

Autocomplete.prototype.highlightOption = function(option) {
  if(this.activeOptionId) {
    var activeOption = this.getOptionById(this.activeOptionId);
    activeOption.setAttribute('aria-selected', 'false');
  }

  option.setAttribute('aria-selected', 'true');

  if(!this.isElementVisible(this.menu, option)) {
    this.menu.scrollTop =
      this.menu.scrollTop + option.getBoundingClientRect().y;
  }

  this.activeOptionId = option.id;
  option.focus();
};

Autocomplete.prototype.getOptionById = function(id) {
  return document.querySelector('#' + id);
};

Autocomplete.prototype.showMenu = function() {
  this.menu.classList.remove('hidden');
  this.textBox.setAttribute('aria-expanded', 'true');
};

Autocomplete.prototype.hideMenu = function() {
  this.menu.classList.add('hidden');
  this.textBox.setAttribute('aria-expanded', 'false');
  this.activeOptionId = null;
  this.clearOptions();
};

Autocomplete.prototype.clearOptions = function() {
  while (this.menu.firstChild) {
    this.menu.removeChild(this.menu.firstChild);
  }
};

Autocomplete.prototype.getOptions = function(value) {
  const matches = [];
  this.select.querySelectorAll('option').forEach(function(el, i) {

    if(el.value.trim().length > 0 && el.textContent.toLowerCase().indexOf(value.toLowerCase()) > -1
        || el.getAttribute('data-alt') && el.getAttribute('data-alt').toLowerCase().indexOf(value.toLowerCase()) > -1) {
      matches.push({
        text: el.textContent,
        value: el.value
      });
    }
  });
  return matches;
};

Autocomplete.prototype.optionsFromDOM = function(options) {
  const filtered = [];
  var option;
  for(var i = 0; i < options.length; i++) {
    option = options[i];
    var value = option.getAttribute('value').trim();
    if(value.length > 0) {
      filtered.push({
        text: option.textContent.trim(),
        value: value
      });
    }
  }
  return filtered;
}

Autocomplete.prototype.getAllOptions = function() {

  let options = this.select.querySelectorAll('option');
  options = this.optionsFromDOM(options);

  let tags = this.container.querySelectorAll('span.ly-tag-name')
  tags = this.optionsFromDOM(tags);
  tags = tags.map(tag => tag.value)

  const res = options.filter(o => ! tags.includes(o.value));
  return res;
};

Autocomplete.prototype.isExactMatch = function(value) {
  return this.getMatchingOption(value);
};

Autocomplete.prototype.getMatchingOption = function(value) {
  var option = null;
  var options = this.select.querySelectorAll('options');
  for(var i = 0; i < options.length; i++) {
    if(options[i].textContent.toLowerCase() === value.toLowerCase()) {
      option = options[i];
      break;
    }
  }
  return option;
};

Autocomplete.prototype.buildMenu = function(options) {
  this.clearOptions();
  this.activeOptionId = null;

  if(options.length) {
    for(var i = 0; i < options.length; i++) {
      this.menu.appendChild(this.getOptionHtml(i, options[i]));
    }
  } else {
    this.menu.appendChild(this.getNoResultsOptionHtml());
  }
  this.menu.scrollTop = this.menu.scrollTop;
};

Autocomplete.prototype.getNoResultsOptionHtml = function() {
  const li = document.createElement('li');
  li.classList.add('autocomplete-optionNoResults');
  li.innerHTML = this.noResults;
  return li;
};

Autocomplete.prototype.getOptionHtml = function(i, option) {
  const li = document.createElement('li');
  li.setAttribute('tabindex', '-1');
  li.setAttribute('aria-selected', 'false');
  li.setAttribute('role', 'option');
  li.setAttribute('data-option-value', option.value)
  li.id = 'autocomplete-option--' + i;
  li.textContent = option.text
  return li;
};

Autocomplete.prototype.createStatusBox = function() {
  const statusDiv = document.createElement('div');
  statusDiv.setAttribute('aria-live', 'polite');
  statusDiv.setAttribute('role', 'status');
  statusDiv.classList.add('visually-hidden');
  this.status = statusDiv;
  this.wrapper.appendChild(statusDiv)
};

Autocomplete.prototype.updateStatus = function(resultCount) {
  if(resultCount === 0) {
    this.status.textContent = 'No results.';
  } else {
    this.status.textContent = resultCount + ' results available.';
  }
};

Autocomplete.prototype.hideSelectBox = function() {
  this.select.setAttribute('aria-hidden', 'true');
  this.select.setAttribute('tabindex', '-1');
  this.select.classList.add('visually-hidden');
  this.select.id = '';
};


Autocomplete.prototype.addTextBoxEvents = function() {
  this.textBox.addEventListener('click', this.onTextBoxClick.bind(this));
  this.textBox.addEventListener('keydown', (e) => {
    switch (e.keyCode) {
      case this.keys.tab:
        this.hideMenu();
        this.removeTextBoxFocus();
        break;
    }
  });
  this.textBox.addEventListener('keyup', this.onTextBoxKeyUp.bind(this));
  this.textBox.addEventListener('focus', this.onTextBoxFocus.bind(this));
}

Autocomplete.prototype.getOptionsId = function() {
  return 'autocomplete-options--' + this.select.id;
};

Autocomplete.prototype.addChevronEvents = function() {
  this.chevron.addEventListener('click', this.onChevronClick.bind(this));
}

Autocomplete.prototype.onChevronClick = function(e) {
  this.clearOptions();
  var options = this.getAllOptions();
  this.buildMenu(options);
  this.updateStatus(options.length);
  this.showMenu();
  this.textBox.focus();
};

Autocomplete.prototype.addMenuEvents = function() {
  this.menu.addEventListener('click', this.onOptionClick.bind(this));
  this.menu.addEventListener('keydown', this.onMenuKeyDown.bind(this));
  //close menu if we click somewhere else
  document.addEventListener('click', this.onDocumentClick.bind(this));
}

Autocomplete.prototype.isElementVisible = function(container, element) {
  const containerStyles = window.getComputedStyle(container)
  const elementStyles = window.getComputedStyle(element)
  const containerHeight = parseFloat(getComputedStyle(container, null).height.replace("px", ""))

  const containerOffset = { top: container.getBoundingClientRect().top + document.body.scrollTop };
  const elementOffset = { top: element.getBoundingClientRect().top + document.body.scrollTop };
  var elementTop = elementOffset.top;
  var containerTop = containerOffset.top;
  var elementPaddingTop = parseInt(elementStyles.paddingTop, 10);
  var elementPaddingBottom = parseInt(elementStyles.paddingBottom, 10);
  var elementHeight = parseFloat(getComputedStyle(element, null).height.replace("px", "")) + elementPaddingTop + elementPaddingBottom;
  var visible;

  if ((elementTop - containerTop < 0) || (elementTop - containerTop + elementHeight > containerHeight)) {
    visible = false;
  } else {
    visible = true;
  }
  return visible;
};

Autocomplete.prototype.getOption = function(value) {
  return this.select.querySelector('option[value="' + value + '"]');
};

Autocomplete.prototype.setValue = function(val) {
  this.select.value = val;
  var text = this.getOption(val).textContent;
  if(val.trim().length > 0) {
    this.textBox.value = text;
  } else {
    this.textBox.value = '';
  }
 this.textBox.dispatchEvent(
    new Event('ly-autocomplete-setinput', {bubbles: true})
 );
};
