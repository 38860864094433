import Base from '../ly-base/ly-base.js';
import tmpl from './template.mustache';
import baseErrorTmpl from './_base-error.mustache';
import nonBaseErrorTmpl from './_non-base-error.mustache';
import ErrorMessages from './error-messages.js';
import ErrorsRenderer from './errors-renderer.js';

class LyErrorSummary extends Base {

  get properties() {
    return {
      hasErrors: this.hasErrors,
      baseErrorsHtml: this.baseErrorsHtml,
      nonBaseErrorsHtml: this.nonBaseErrorsHtml,
      displayOnRender: this.displayOnRender
    };
  }

  connectedCallback() {
    super.connectedCallback();
    if (this.hasErrors) {
      this.DOM.errorSummary.focus();
    }
  }

  static get observedAttributes() { return ['ly-error-message'] };
  attributeChangedCallback(name, oldValue, newValue) {
    if (!this._rendered) { return; };

    if (this.isOpen) {
      //this is designed to handle the case where errors are already shown
      //and then click submit again necessitates showing the same or other
      //errors. The rerender() is designed to trick it into showing again.
      this._rerender();
      this.transition(oldValue, newValue, this._rerender);
    } else {
      this._rerender();
      this.transition(oldValue, newValue);
    }
  }

  get isOpen(){
    return (!!this.DOM.errorSummary);
  }

  get DOM(){
    return {
      errorSummary: this.querySelector('.ly-error-summary')
    }
  }

  get displayOnRender() {
    return this.hasAttribute('ly-open');
  }

  get baseErrorsHtml() {
    return this.baseErrorsRenderer.toHtmlString();
  }

  get nonBaseErrorsHtml() {
    return this.nonBaseErrorsRenderer.toHtmlString();
  }

  get hasErrors() {
    return !this.errorMessages.isEmpty;
  }

  get errorMessages(){
    return new ErrorMessages(this.errorMessage);
  }

  get baseErrorsRenderer(){
    return new ErrorsRenderer(this.errorMessages.baseErrors, baseErrorTmpl);
  }

  get nonBaseErrorsRenderer() {
    return new ErrorsRenderer(
      this.errorMessages.nonBaseErrors, nonBaseErrorTmpl
    );
  }

  transition(oldValue, newValue, afterLeave = null) {
    if (!this.isOpen) { return; };
    if (!newValue) { this.transitionOut(afterLeave); return; }

    this.transitionIn();
    return
  }

  transitionIn() {
    this.transitions(this.DOM.errorSummary).enter();
    this.DOM.errorSummary.focus();
  }

  transitionOut(afterLeave) {
    this.transitions(this.DOM.errorSummary).leave(afterLeave.bind(this));
  }

  get tmpl() {
    return tmpl;
  }
}

window.customElements.define('ly-error-summary', LyErrorSummary);
