export default class ErrorMessages {
  constructor(errors){
    this._errors  = !!errors ? JSON.parse(errors) : {};
  }

  get errors(){
    return this._errors;
  }

  get keys() {
    return Object.keys(this.errors);
  }

  get values() {
    return Object.values(this.errors);
  }

  get isEmpty() {
    return this.values.flat().every(this.valIsEmpty);
  }

  valIsEmpty(str) {
    return (!str || str.length === 0 );
  }

  // return [['base', ['e1', 'e2']]
  // (Same shape as nonBaseErrors)
  get baseErrors() {
    return Object.entries(this.errors).filter(tuple => tuple[0] === 'base');
  }

  // return [['key1', [e1, e2], ['key2, ['e1', 'e2', 'e3'], ...]
  // (Same shape as baseErrors)
  get nonBaseErrors() {
    return Object.entries(this.errors).filter(tuple => tuple[0] !== 'base');
  }


}
