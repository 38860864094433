import Base from '../ly-base/ly-base.js';
import tmpl from './template.mustache';
import spinnerTmpl from './_spinner.mustache';
import wingtaskLogoTmpl from './logos/_wingtask-logo.mustache';
import lycraLogoTmpl from './logos/_lycra-logo.mustache';
import notesLogoTmpl from './logos/_notes-logo.mustache';
import hotLinksLogoTmpl from './logos/_hot-links-logo.mustache';
import './ly-logo.css';

class Logo extends Base {

  constructor() {
    super();
    this._logoClassList = '';
    this._spinnerClassList = '';
    this._imageSrc = 'wingtask-logo';
    this._spinnerSrc = 'audio';
  }

  get properties() {
    return {
      imageSrc: this.imageSrc,
      spinnerSrc: this.spinnerSrc,
      isLoading: this.isLoading,
      logoClassList: this.logoClassList,
      spinnerClassList: this.spinnerClassList
    };
  }

  get logoClassList() {
    return this.getAttribute('ly-logo-class') || this._logoClassList;
  }

  get spinnerClassList() {
    return this.getAttribute('ly-spinner-class') || this._spinnerClassList;
  }

  get tmpl() {
    return tmpl;
  }

  get imageSrc() {
    return this.getAttribute('ly-image-src') || this._imageSrc;
  }

  get spinnerSrc() {
    return this.getAttribute('ly-spinner-src') || this._spinnerSrc;
  }

  get isLoading() {
     return this.getAttribute('ly-is-loading') === 'true';
  }

  static get observedAttributes() { return ['ly-is-loading']; }
  attributeChangedCallback(name, oldValue, newValue) {
    if (this._rendered !== true) return;
    switch (name) {
      case 'ly-is-loading':
        if (newValue !== oldValue) {
          this._rerender();
        }
      default:
    }
  }
  get svgTmpls() {
    return {
      'wingtask-logo': wingtaskLogoTmpl,
      'lycra-logo': lycraLogoTmpl,
      'notes-logo': notesLogoTmpl,
      'hot-links-logo': hotLinksLogoTmpl
    };
  }

  get logoTmpl() {
    return this.svgTmpls[this.imageSrc];
  }

  get spinnerTmpl() {
    return spinnerTmpl;
  }

  get partials() {
    return {
      logoTmpl: this.logoTmpl,
      spinnerTmpl: this.spinnerTmpl
    };
  }
}

window.customElements.define('ly-logo', Logo);
