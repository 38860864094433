export default class OptionCreator {
  constructor(entry){
    this._entry = entry
  }

  get option(){
    const o = document.createElement('option');
    return this._entryIsString ? this._fromStrEntry(o) : this._fromObjEntry(o);
  }

  get _entryIsString() {
    return (typeof this._entry === "string");
  }

  _fromStrEntry(opt) {
    opt.value = this._entry;
    opt.textContent = this._entry;
    return opt;
  }

  _fromObjEntry(opt) {
    opt.value = this._entry.value;
    opt.textContent = this._entry.name;
    opt.setAttribute('data-alt', this._entry.dataAlt);
    return opt;
  }

}
