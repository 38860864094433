import Base from '../ly-base/ly-base.js';
import tmpl from './template.mustache';
import './ly-radio.css';

class LyRadio extends Base {

  connectedCallback() {
    super.connectedCallback();

    this.addEventListener("change", e => {
      e.target.dispatchEvent(new Event('ly-radio-change', {bubbles: true}));
    });
  }

  get tmpl() {
    return tmpl;
  }
}


window.customElements.define('ly-radio', LyRadio);
