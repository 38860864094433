import LyInput from '../ly-input/ly-input.js';
import tmpl from './template.mustache';

class LyTextarea extends LyInput {
  constructor() {
    super();
    this._rows = null;
  }

  get properties() {
    return {
      rows: this.rows
    }
  }

  get rows() {
    return this.getAttribute('ly-rows') || this._rows;
  }

  get tmpl() {
    return tmpl;
  }

static get observedAttributes() { return ['ly-has-error']; }
  attributeChangedCallback(name, oldValue, newValue) {
    if (this._rendered !== true) return;
    switch (name) {
      case 'ly-has-error':
        if (newValue !== oldValue) {
          this._rerender();
        }
        break;
      default:
    }
  }
}

window.customElements.define('ly-textarea', LyTextarea);
