import Environment from './environment.js';
import SentryInit from './initializers/sentry_init.js';

export default class Production extends Environment {
  constructor(config){
    super(config)
    this._name = 'production';
    this._sentry = new SentryInit(config)
  }

  init() {
    super.init();
    this._sentry.init();
  }
}
