import Base from '../ly-base/ly-base.js';
import tmpl from './template.mustache';
import inputTmpl from './_input.mustache';
import autocompleteTmpl from './_autocomplete.mustache';
import successTmpl from './_success.mustache';
import { DatePicker } from './date-picker.js';
import './date-picker.css'
import autocompleteOptions from './autocompleteOptions.json';

class LyDatePicker extends Base {

  constructor() {
    super();
    this._posix = '';
  }

  connectedCallback() {
    if (this._rendered) {
      return;
    }
    super.connectedCallback();
    if (!this.input) {
      setTimeout(() => {
        new DatePicker(this.input);
      }, 1);
    } else {
      new DatePicker(this.input);
    }
  }

  static get observedAttributes() { return ['ly-posix', 'ly-has-error']; }
  attributeChangedCallback(name, oldValue, newValue) {
    if (this._rendered !== true) return;
    switch (name) {
      case 'ly-posix':
        if (this.isOpen) {
          this.transition(oldValue, newValue, this._rerender);
        } else {
          this._rerender();
          this.transition(oldValue, newValue);
        }
        break;
      case 'ly-has-error':
        if (newValue !== oldValue) {
          this._rerender();
          new DatePicker(this.input);
        }
        break;
      default:
    }
  }

  get posix(){
    return this.getAttribute('ly-posix') || this._posix;
  }

  get isOpen(){
    return (!!this.DOM.successContainer);
  }

  renderSuccessTemplate(successMsg) {
    return successTmpl.render({successMessage: this.posix});
  }

  showSuccessMessage(successMsg) {
    this.DOM.successContainer.innerHTML =
      this.renderSuccessTemplate(successMsg);
  }

  removeSuccessMessage() {
    this._removeChildNodesForElement(this.DOM.successContainer);
  }

  get DOM(){
    return {
      successContainer: this.querySelector('.success-container')
    };
  }

  transition(oldValue, newValue) {
    if (!newValue) { this.transitionOut(); return; }

    this.transitionIn();
    return

  }

  transitionIn() {
    this.transitions(this.DOM.successContainer).enter(
      this.showSuccessMessage.bind(this)
    );
  }

  transitionOut() {
    this.transitions(this.DOM.successContainer).leave(
      this.removeSuccessMessage.bind(this)
    );
  }

  get properties() {
    return {
      isAutocomplete: this.isAutocomplete,
      autocompleteOptions: this.autocompleteOptions
    };
  }

  get tmpl() {
    return tmpl;
  }

  get isAutocomplete() {
    return this.inputType === 'autocomplete';
  }

  get autocompleteOptions(){
    return JSON.stringify(autocompleteOptions);
  }

  get input() {
    return this.querySelector('input');
  }

  get partials() {
    return {
      input: inputTmpl,
      autocomplete: autocompleteTmpl,
      success: successTmpl
    };
  }
}

window.customElements.define('ly-date-picker', LyDatePicker);
