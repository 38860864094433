import Base from '../ly-base/ly-base.js';
import tmpl from './template.mustache';
import Autocomplete from './autocomplete.js';
import OptionCreator from './option-creator.js';
import './autocomplete.css'

export default class LyAutocomplete extends Base {

  constructor() {
    super();
    this._autocompleteHelper = null;
  }

  // Not using base _buildComponetDom because mustache
  // won't render anything but option tags inside of a select
  // so it makes using <slot> difficult
  _buildComponentDom() {
    this.optionsJson.forEach(entry => {
      const option =
        new OptionCreator(entry).option
      this.select.appendChild(option)
    })
    this.insertAdjacentHTML('beforeend', this._tmplToString);
  }

  get tmpl() {
    return tmpl;
  }

  connectedCallback() {
    if (this._rendered) {
      return;
    }
    super.connectedCallback();
    this._autocompleteHelper = new Autocomplete(this.select, this.noResults);
  }

  createSelect() {
    this.appendChild(document.createElement('select'));
    return this.querySelector('select');
  }

  get optionsJson() {
    const attr = this.getAttribute('ly-options')
    return attr ? JSON.parse(attr) : []
  }

  get select() {
    return this.querySelector('select') || this.createSelect();
  }

  get input()  {
    return this.querySelector('input');
  }

  get autocompleteHelper() {
    return this._autocompleteHelper;
  }

  get options() {
    return this.autocompleteHelper.getAllOptions();
  }

  get noResults() {
    return 'No Results'
  }
}

window.customElements.define('ly-autocomplete', LyAutocomplete);
