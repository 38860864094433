import Base from "../ly-base/ly-base.js";
import tmpl from "./template.mustache";
import warningTmpl from "./warning.mustache";
import dangerTmpl from "./danger.mustache";

class LyAlert extends Base {
    get properties() {
        return {
            header: this.header,
        };
    }

    get alertTmpls() {
        return {
            warning: warningTmpl,
            danger: dangerTmpl,
        };
    }

    get name() {
        return this.getAttribute("ly-name");
    }

    get tmpl() {
        return this.alertTmpls[this.name] || this.alertTmpls.warning;
    }

    get header() {
        return this.getAttribute("ly-header");
    }
}

window.customElements.define("ly-alert", LyAlert);
