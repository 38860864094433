import Base from "../ly-base/ly-base.js";
import tmpl from "./template.mustache";
import emptyTmpl from "./banners/_empty.mustache";
import demoTmpl from "./banners/_demo.mustache";
import rootCertAlertTmpl from "./banners/_root_cert_alert.mustache";
import rootCertAlertMinTmpl from "./banners/_root_cert_alert_min.mustache";
import tagsIssueTmpl from "./banners/_2_6_0_tags_issue.mustache";
import taskwarriorThreeOhTmpl from "./banners/_taskwarrior_3_0.mustache";
import "./ly-app-banner.css";

class LyAppBanner extends Base {
    constructor() {
        super();
        this._bannerSrc = "empty";
    }

    connectedCallback() {
        super.connectedCallback();
        this.addEventListeners();
    }

    disconnectedCallback() {
        this.removeEventListeners();
    }

    addEventListeners() {
        if (this.DOM.signupButton) {
            this.DOM.signupButton.addEventListener(
                "click",
                this.signupButtonClickHandler.bind(this),
            );
        }
        if (this.DOM.closeButton) {
            this.DOM.closeButton.addEventListener(
                "click",
                this.closeButtonClickHandler.bind(this),
            );
        }
    }

    removeEventListeners() {
        if (this.DOM.signupButton) {
            this.DOM.signupButton.removeEventListener(
                "click",
                this.signupButtonClickHandler,
            );
        }
        if (this.DOM.closeButton) {
            this.DOM.closeButton.removeEventListener(
                "click",
                this.closeButtonClickHandler,
            );
        }
    }

    signupButtonClickHandler() {
        window.open("https://app.wingtask.com/signup");
    }

    handleCloseClick() {
        this.DOM.banner.classList.add("hidden");
        this.DOM.banner.dispatchEvent(
            new Event("ly-banner-closed", { bubbles: true }),
        );
    }

    get handlers() {
        return {
            handleCloseClick: this.handleCloseClick,
        };
    }

    get DOM() {
        return {
            signupButton: this.querySelector("button.app-banner-signup"),
            closeButton: this.querySelector("button.app-banner-close"),
            banner: this.querySelector(".ly-app-banner"),
        };
    }

    get bannerTmpls() {
        return {
            empty: emptyTmpl,
            demo: demoTmpl,
            "root-cert-alert": rootCertAlertTmpl,
            "root-cert-alert-min": rootCertAlertMinTmpl,
            "2-6-0-tags-issue": tagsIssueTmpl,
            "taskwarrior-3-0": taskwarriorThreeOhTmpl,
        };
    }

    get tmpl() {
        return tmpl;
    }

    get bannerSrc() {
        return this.getAttribute("ly-banner-src") || this._bannerSrc;
    }

    get partials() {
        console.log(this.bannerSrc);
        return {
            bannerTmpl: this.bannerTmpls[this.bannerSrc],
        };
    }
}

window.customElements.define("ly-app-banner", LyAppBanner);
