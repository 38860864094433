import Base from '../ly-base/ly-base.js';
import tmpl from './template.mustache';
import './ly-copy-to-clipboard.css'

class LyCopyToClipboard extends Base {
  constructor(){
    super()
    this._payload = '';
  }

  get properties() {
    return {
      payload: this.payload
    }
  }

  get tmpl() {
    return tmpl;
  }

  get DOM() {
    return {
      payload: this.querySelector('textarea.payload'),
    }
  }

  get payload(){
    return this.getAttribute('ly-payload') || this._payload;
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('ly-input-focus', e => {
      this.selectPayload();
    });
  }

  handleClick() {
    this.selectPayload();
    document.execCommand("copy");
  }

  selectPayload() {
    this.DOM.payload.select();
    this.DOM.payload.setSelectionRange(0, 99999); /* for mobile */
  }

  get handlers() {
    return {
      'handleClick': this.handleClick
    }
  }
}

window.customElements.define('ly-copy-to-clipboard', LyCopyToClipboard);
