import Base from '../ly-base/ly-base.js';
import tmpl from './template.mustache';
import './ly-demo-banner.css';


class LyDemoBanner extends Base {

  connectedCallback() {
    super.connectedCallback();
    this.addEventListeners();
  }

  disconnectedCallback() {
    this.removeEventListeners();
  }

  addEventListeners() {
    this.DOM.signupButton.addEventListener(
      'click', this.signupButtonClickHandler.bind(this)
    );
    this.DOM.closeButton.addEventListener(
      'click', this.closeButtonClickHandler.bind(this)
    );
  }

  removeEventListeners() {
    this.DOM.signupButton.removeEventListener(
      'click', this.signupButtonClickHandler
    );
    this.DOM.closeButton.removeEventListener(
      'click', this.closeButtonClickHandler
    );
  }

  signupButtonClickHandler() {
    window.open('https://app.wingtask.com/signup');
  }

  closeButtonClickHandler() {
    this.DOM.banner.classList.remove('demo-banner-block');
    this.DOM.banner.classList.add('demo-banner-hidden');
  }


  get DOM(){
    return {
      signupButton: this.querySelector('button.demo-banner-signup'),
      closeButton: this.querySelector('button.demo-banner-close'),
      banner: this.querySelector('div.demo-banner')
    };
  }

  get tmpl() {
    return tmpl;
  }
}

window.customElements.define('ly-demo-banner', LyDemoBanner);
