import Base from '../ly-base/ly-base.js';
import tmpl from './template.mustache';
import  QRCode  from 'qrcode-svg';

export default class LyQrCode extends Base {
  constructor(){
    super()
    this._qrcode = null;
    this._width = 256
    this._height = 256
  }

  get width(){
    return this.getAttribute('ly-width') || this._width;
  }

  get height() {
    return this.getAttribute('ly-height') || this._height;
  }

  get qrcode(){
    return(
      new QRCode(
        {content: this.payload, width: this.width, height: this.height}
      ).svg()
    );
  }

  get payload(){
    return this.getAttribute('ly-payload') || this._payload;
  }

  get tmpl() {
    return tmpl;
  }

  get properties() {
    return {
      qrcode: this.qrcode
    }
  }
}

window.customElements.define('ly-qrcode', LyQrCode);
