import Base from '../ly-base/ly-base.js';
import tmpl from './template.mustache';
import Linkify from './Linkify.js';

class LyDescription extends Base {

  get properties(){
    return {'text': this.text};
  }

  get tmpl() {
    return tmpl;
  }

  connectedCallback() {
    this._rawText = this.textContent;
    super.connectedCallback();
  }

  get text(){
    return Linkify(this._rawText, this.className);
  }
}


window.customElements.define('ly-description', LyDescription);
