import Base from '../ly-base/ly-base.js';
import tmpl from './template.mustache';

class LyUrgencyItem extends Base {

  constructor() {
    super();
    this._description = '';
  }

  get properties() {
    return {
      description: this.description,
      urgency: this.urgency,
    };
  }

  get tmpl() {
    return tmpl;
  }

  get description() {
    return this.getAttribute('description') || this._description;
  }

  get urgency() {
    return parseFloat(this.getAttribute('urgency')).toPrecision(3).toString();
  }
}

window.customElements.define('ly-urgency-item', LyUrgencyItem);
