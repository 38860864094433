import Base from '../ly-base/ly-base.js';
import tmpl from './template.mustache';

class Link {
  constructor(href, text){
    this._href = href
    this._text = text
  }

  get href(){
    return this._href;
  }

  get text(){
    return this._text;
  }
}

export default class LyBreadcrumb extends Base {
  constructor(){
    super()
    this._path = '';
  }

  connectedCallback() {
    if (this.path !== '/') {
      this._render();
    }
  }

  get path(){
    return this.getAttribute('ly-path') || this._path;
  }

  get backHref(){
    return this.sliceHref(-1);
  }

  get firstSegment() {
    if (this.segments[0] === 'tasks') {
      return new Link(
        "/", "<ly-icon ly-name='home' ly-class='h-5 w-auto'><ly-icon>"
      )
    }
    else {
      return this.linkForIndex(0);
    }
  }

  get intermediateSegments() {
    let res = [];
    for (let i = 1, len = this.segments.length - 2; i <= len; i++) {
      res.push(this.linkForIndex(i));
    }
    return res;
  }

  get lastSegment(){
    return this.linkForIndex(this.segments.length - 1)
  }

  linkForIndex(index) {
    return new Link(this.sliceHref(index  + 1), this.segments[index])
  }

  sliceHref(end) {
    return '/' + this.segments.slice(0, end).join('/');
  }

  get segments(){
    return this.path.split('/').filter(elem => { return elem });
  }

  get tmpl() {
    return tmpl;
  }

  get properties() {
    return {
      path: this.path,
      backHref: this.backHref,
      firstSegment: this.firstSegment,
      lastSegment: this.lastSegment,
      intermediateSegments: this.intermediateSegments
    }
  }
}

customElements.define("ly-breadcrumb", LyBreadcrumb);
