import LyAutocomplete from '../ly-autocomplete/ly-autocomplete.js';
import tmpl from './template.mustache';
import autocompleteTmpl from '../ly-autocomplete/template.mustache';
import tagsTmpl from './_tags.mustache';
import hiddenTmpl from './_hidden.mustache';
import './ly-tag-input.css';

class LyTagInput extends LyAutocomplete {

  constructor() {
    super();
  }

  get properties() {
    return Object.assign(super.properties, { selectedTags: this.selectedTags});
  }

  get tmpl() {
    return tmpl;
  }

  connectedCallback() {
    if (this._rendered) {
      return;
    }
    super.connectedCallback();

    this.addEventListeners();
  }

  static get observedAttributes() { return ['ly-selected-tags']; }
  attributeChangedCallback(name, oldValue, newValue) {
    if (!this._rendered) {
      return;
    }
    switch (name) {
      case 'ly-selected-tags':
        const oldTags = new Set(JSON.parse(oldValue));
        const newTags = new Set(JSON.parse(newValue));

        if (oldTags) {
          const tagsToRemove = new Set(
            [...oldTags].filter(x => !newTags.has(x)));

          const tagsToAdd = new Set(
            [...newTags].filter(x => !oldTags.has(x)));

          this.removeTags(Array.from(tagsToRemove));

          this.addTags(Array.from(tagsToAdd));

          this.DOM.hiddenInput.setAttribute(
            "value", this.selectedTags.join(",")
          )
        }
        break;
      default:
    }
  }

  //when a tag is added a dropdown option must be removed
  //when a tag is removed and that tag exists in the
  //original available tags that dropdown option must be added
  addTags(tagsToAdd) {
    tagsToAdd.forEach(tag => {
      const newTag = document.createElement('ly-tag');
      newTag.setAttribute('ly-class', 'ly-tag-input-tag');
      newTag.setAttribute('ly-name', tag);
      this.DOM.tags.appendChild(newTag);
    })
    this.autocompleteHelper.hideMenu();
  }

  removeTags(tagsToRemove) {
    tagsToRemove.forEach(tag => {
      const tagToRemove = this.querySelector(`ly-tag[ly-name='${tag}']`);
      this.DOM.tags.removeChild(tagToRemove);
    })
  }

  removeMenuOptions(optionsToRemove) {
    const newOptions =
      this.availableTags.filter(tag => !optionsToRemove.includes(tag.value));
    this.autocompleteHelper.rebuildMenu(newOptions);
  }

  get DOM() {
    return {
      tags: this.querySelector('.ly-tag-input-tags'),
      hiddenInput: this.querySelector("input[type='hidden']")
    }
  }

  get availableTags() {
    this._availableTags;
  }

  get selectedTags() {
    const attr = this.getAttribute('ly-selected-tags');
    return attr ? JSON.parse(attr) : [];
  }

  get partials() {
    return {
      tags: tagsTmpl,
      autocomplete: autocompleteTmpl,
      hidden: hiddenTmpl
    };
  }

  get availableTags() {
    return this.options;
  }

  get noResults() {
    let res = '<div class="no-results-desktop">';
    res += 'Press spacebar or tab to enter a new tag';
    res += '</div>';
    res += '<div class="no-results-mobile">';
    res += 'Tap outside to add tag';
    res += '</div>';
    return res;
  }

  addEventListeners() {
    this.input.addEventListener('keydown', (e) => {
      switch (e.keyCode) {
        case this.autocompleteHelper.keys.space:
          e.preventDefault();
          const val = this.input.value.trim();
          if (!!val) {
            this.dispatchEvent(
              new CustomEvent(
                'ly-tag-input-keydown-spacebar',
                {detail: this.input.value.trim(), bubbles: true}
              )
            )
          }
          this.input.value = '';
          break;
      }
    });

    this.addEventListener('ly-autocomplete-setinput', e => {
      this.dispatchEvent(
        new CustomEvent(
          'ly-tag-input-autocomplete-setinput',
          {detail: e.target.value, bubbles: true}
        )
      )
      this.input.value = '';
    });

    this.addEventListener('ly-input-focusout', e => {
      if (e.target.value === '') { return; }

      this.dispatchEvent(
        new CustomEvent(
          'ly-tag-input-input-focusout',
          {detail: e.target.value, bubbles: true}
        )
      )
      this.input.value = '';
    });

  }
}

window.customElements.define('ly-tag-input', LyTagInput);
