import Base from "../ly-base/ly-base.js";

import annotationTmpl from "./icons/annotation.mustache";
import boltTmpl from "./icons/bolt.mustache";
import broomWideTmpl from "./icons/broom-wide.mustache";
import calendarOutlineTmpl from "./icons/calendar-outline.mustache";
import calendarSolidTmpl from "./icons/calendar-solid.mustache";
import checkCircleTmpl from "./icons/check-circle.mustache";
import checkTmpl from "./icons/check.mustache";
import chevronDoubleDownOutlineTmpl from "./icons/chevron-double-down-outline.mustache";
import chevronDoubleDownSolidTmpl from "./icons/chevron-double-down-solid.mustache";
import chevronDoubleUpOutlineTmpl from "./icons/chevron-double-up-outline.mustache";
import chevronDoubleUpSolidTmpl from "./icons/chevron-double-up-solid.mustache";
import chevronDownOutlineTmpl from "./icons/chevron-down-outline.mustache";
import chevronDownSolidTmpl from "./icons/chevron-down-solid.mustache";
import chevronLeftOutlineTmpl from "./icons/chevron-left-outline.mustache";
import chevronLeftSolidTmpl from "./icons/chevron-left-solid.mustache";
import chevronRightOutlineTmpl from "./icons/chevron-right-outline.mustache";
import chevronRightSolidTmpl from "./icons/chevron-right-solid.mustache";
import chevronUpOutlineTmpl from "./icons/chevron-up-outline.mustache";
import chevronUpSolidTmpl from "./icons/chevron-up-solid.mustache";
import circleStackTmpl from "./icons/circle-stack.mustache";
import clipboardCopySolidTmpl from "./icons/clipboard-copy.mustache";
import cogTmpl from "./icons/cog.mustache";
import collectionOutlineTmpl from "./icons/collection-outline.mustache";
import collectionSolidTmpl from "./icons/collection-solid.mustache";
import computerDesktopTmpl from "./icons/computer-desktop.mustache";
import cpuChipTmpl from "./icons/cpu-chip.mustache";
import cubeTmpl from "./icons/cube.mustache";
import cubeTransparentTmpl from "./icons/cube-transparent.mustache";
import devicePhoneMobileTmpl from "./icons/device-phone-mobile.mustache";
import deviceTabletTmpl from "./icons/device-tablet.mustache";
import documentTextTmpl from "./icons/document-text.mustache";
import dotsVerticalTmpl from "./icons/dots-vertical.mustache";
import downloadTmpl from "./icons/download.mustache";
import elmTmpl from "./icons/elm.mustache";
import exclamationOutlineTmpl from "./icons/exclamation-outline.mustache";
import exclamationSolidTmpl from "./icons/exclamation-solid.mustache";
import externalLinkTmpl from "./icons/external-link.mustache";
import eyeTmpl from "./icons/eye.mustache";
import faceExplodeTmpl from "./icons/face-explode.mustache";
import folderOutlineTmpl from "./icons/folder-outline.mustache";
import folderSolidTmpl from "./icons/folder-solid.mustache";
import globeAltTmpl from "./icons/globe-alt.mustache";
import homeOutlineTmpl from "./icons/home-outline.mustache";
import homeSolidTmpl from "./icons/home-solid.mustache";
import infoCircleTmpl from "./icons/information-circle.mustache";
import lightBulbTmpl from "./icons/light-bulb.mustache";
import mailTmpl from "./icons/mail.mustache";
import pencilAltTmpl from "./icons/pencil-alt.mustache";
import plusTmpl from "./icons/plus.mustache";
import printerTmpl from "./icons/printer.mustache";
import puzzlePieceTmpl from "./icons/puzzle-piece.mustache";
import radioTmpl from "./icons/radio.mustache";
import refreshTmpl from "./icons/refresh.mustache";
import rssTmpl from "./icons/rss.mustache";
import searchTmpl from "./icons/search.mustache";
import serverTmpl from "./icons/server.mustache";
import signoutTmpl from "./icons/signout.mustache";
import supportTmpl from "./icons/support.mustache";
import tagOutlineTmpl from "./icons/tag-outline.mustache";
import tagSolidTmpl from "./icons/tag-solid.mustache";
import terminalTmpl from "./icons/terminal.mustache";
import trashTmpl from "./icons/trash.mustache";
import tvTmpl from "./icons/tv.mustache";
import viewListTmpl from "./icons/view-list.mustache";
import webcomponentsTmpl from "./icons/webcomponents.mustache";
import wifiTmpl from "./icons/wifi.mustache";
import xTmpl from "./icons/x.mustache";

const PLACEHOLDER_ICON = "cube-transparent";

export default class LyIcon extends Base {
    constructor() {
        super();
        this._name = PLACEHOLDER_ICON;
    }

    get iconTmpls() {
        return {
            annotation: annotationTmpl,
            bolt: boltTmpl,
            "broom-wide": broomWideTmpl,
            "check-circle": checkCircleTmpl,
            "circle-stack": circleStackTmpl,
            "computer-desktop": computerDesktopTmpl,
            "cpu-chip": cpuChipTmpl,
            cube: cubeTmpl,
            "device-phone-mobile": devicePhoneMobileTmpl,
            "device-tablet": deviceTabletTmpl,
            elm: elmTmpl,
            eye: eyeTmpl,
            "face-explode": faceExplodeTmpl,
            "globe-alt": globeAltTmpl,
            "light-bulb": lightBulbTmpl,
            "pencil-alt": pencilAltTmpl,
            printer: printerTmpl,
            "puzzle-piece": puzzlePieceTmpl,
            radio: radioTmpl,
            rss: rssTmpl,
            server: serverTmpl,
            trash: trashTmpl,
            tv: tvTmpl,
            wifi: wifiTmpl,
            "calendar-outline": calendarOutlineTmpl,
            "calendar-solid": calendarSolidTmpl,
            check: checkTmpl,
            "chevron-double-down-outline": chevronDoubleDownOutlineTmpl,
            "chevron-double-down-solid": chevronDoubleDownSolidTmpl,
            "chevron-double-up-outline": chevronDoubleUpOutlineTmpl,
            "chevron-double-up-solid": chevronDoubleUpSolidTmpl,
            "chevron-down-outline": chevronDownOutlineTmpl,
            "chevron-down-solid": chevronDownSolidTmpl,
            "chevron-left-outline": chevronLeftOutlineTmpl,
            "chevron-left-solid": chevronLeftSolidTmpl,
            "chevron-right-outline": chevronRightOutlineTmpl,
            "chevron-right-solid": chevronRightSolidTmpl,
            "chevron-up-outline": chevronUpOutlineTmpl,
            "chevron-up-solid": chevronUpSolidTmpl,
            "clipboard-copy": clipboardCopySolidTmpl,
            cog: cogTmpl,
            "collection-outline": collectionOutlineTmpl,
            "collection-solid": collectionSolidTmpl,
            "cube-transparent": cubeTransparentTmpl,
            "document-text": documentTextTmpl,
            "dots-vertical": dotsVerticalTmpl,
            download: downloadTmpl,
            exclamation: exclamationOutlineTmpl,
            "exclamation-outline": exclamationOutlineTmpl,
            "exclamation-solid": exclamationSolidTmpl,
            "external-link": externalLinkTmpl,
            folder: folderOutlineTmpl,
            "folder-outline": folderOutlineTmpl,
            "folder-solid": folderSolidTmpl,
            home: homeOutlineTmpl,
            "home-outline": homeOutlineTmpl,
            "home-solid": homeSolidTmpl,
            "info-circle": infoCircleTmpl,
            mail: mailTmpl,
            plus: plusTmpl,
            refresh: refreshTmpl,
            search: searchTmpl,
            signout: signoutTmpl,
            support: supportTmpl,
            tag: tagOutlineTmpl,
            "tag-outline": tagOutlineTmpl,
            "tag-solid": tagSolidTmpl,
            terminal: terminalTmpl,
            "view-list": viewListTmpl,
            webcomponents: webcomponentsTmpl,
            x: xTmpl,
        };
    }

    static get observedAttributes() {
        return ["ly-name"];
    }
    attributeChangedCallback(name, oldValue, newValue) {
        if (this._rendered !== true) return;
        switch (name) {
            case "ly-name":
                if (newValue !== oldValue) {
                    this._rerender();
                }
                break;
            default:
        }
    }
    get tmpl() {
        return this.iconTmpls[this.name];
    }
}

window.customElements.define("ly-icon", LyIcon);
