import Base from '../ly-base/ly-base.js';
import tmpl from './template.mustache';

class LyDateItem extends Base {
  constructor(){
    super()
    this._description = '';
    this._created = '0';
  }

  get properties() {
    return {
      description: this.description,
      posix: this.posix
    };
  }

  get tmpl() {
    return tmpl;
  }

  get description() {
    return this.getAttribute('description') || this._description;
  }

  get created(){
    return this.getAttribute('created') || this._created;
  }

  get posix(){
    return parseInt(this.created);
  }
}

window.customElements.define('ly-date-item', LyDateItem);
