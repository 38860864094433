import Session from './session.js';
export default class Config {

  constructor(){
    this._name = process.env.WINGTASK_ENV_NAME;
    this._apiUrl = process.env.WINGTASK_API_URL;
    this._version = process.env.npm_package_version;
    this._sentryDsn = process.env.WT_FRONT_SENTRY_DSN;
    this._session = new Session();
  }

  get name(){
    return this._name;
  }

  get version(){
    return this._version;
  }

  get apiUrl(){
    return this._apiUrl;
  }

  get session() {
    return this._session;
  }

  get sentryDsn() {
    return this._sentryDsn;
  }

  toString() {
    return JSON.stringify(
      {env: this.name, apiUrl: this.apiUrl, version: this.version}
    );
  }

  get uuidSeed(){
    return Math.floor(Math.random()*0x0FFFFFFF);
  }

}
