import Development from '../development.js';
import Demo from '../demo.js';
import Staging from '../staging.js';
import Production from '../production.js';
import Config from './config';

export default class Factory {
  static create(processEnv) {
    const c = new Config()

    switch (c.name) {
      case 'development':
        return new Development(c);
        break;
      case 'demo':
        return new Demo(c);
        break;
      case 'staging':
        return new Staging(c);
        break;
      case 'production':
        return new Production(c);
        break;
      default:
        return new Development(c);
    }
  }
}
