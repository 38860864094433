import Base from '../ly-base/ly-base.js';
import tmpl from './template.mustache';
import loremTmpl from './content/lorem.mustache';
import calendarTmpl from './content/calendar.mustache';
import descriptionTmpl from './content/description.mustache';
import descriptionViewingTmpl from './content/description-viewing.mustache';
import projectTmpl from './content/project/template.mustache';
import projectData from './content/project/data.json';
import tagsTmpl from './content/tags/template.mustache';
import tagsData from './content/tags/data.json';
import dueTmpl from './content/due/template.mustache';
import dueData from './content/due/data.json'
import statusTmpl from './content/status/template.mustache';
import statusData from './content/status/data.json';
import recurrenceTmpl from './content/recurrence/template.mustache';
import recurrenceData from './content/recurrence/data.json';
import priorityTmpl from './content/priority/template.mustache';
import priorityData from './content/priority/data.json';
import waitTmpl from './content/wait/template.mustache';
import waitData from './content/wait/data.json'
import scheduledTmpl from './content/scheduled/template.mustache';
import scheduledData from './content/scheduled/data.json'
import untilTmpl from './content/until/template.mustache';
import untilData from './content/until/data.json'
import startTmpl from './content/start/template.mustache';
import startData from './content/start/data.json'
import endTmpl from './content/end/template.mustache';
import endData from './content/end/data.json'
import dateAndTimePartial from './content/_date_and_time.mustache';
import virtualTagsPartial from './content/_virtual_tags.mustache';
import synonyms from './content/synonyms.json'
import iso8601 from './content/iso8601.json'
import uuidTmpl from './content/uuid.mustache';
import idTmpl from './content/id.mustache';
import urgencyTmpl from './content/urgency/template.mustache';
import urgencyData from './content/urgency/data.json'
import annotationsTmpl from './content/annotations.mustache';
import './ly-slideover.css';


class LySlideover extends Base {

  constructor() {
    super();
    this._contentId = 'lorem';
  }

  get properties() {
    return {
      title: this.title,
      content: this.content
    };
  }

  get tmpl() {
    return tmpl;
  }

  get transitionIds() {
    return ['slideover', 'overlay'];
  }

  get DOM() {
    return {
      slideover: this.querySelector('#slideover'),
      overlay: this.querySelector('#overlay')
    }
  }

  static get observedAttributes() { return ['ly-open']; }
  attributeChangedCallback(name, oldValue, newValue) {
    switch (name) {
      case 'ly-open':
        if ((oldValue === null) && (newValue === '')) {
          if (this._rendered) {
            break;
          } else {
            this._render();
            this.transitions(this.DOM.overlay).enter()
            this.transitions(this.DOM.slideover).enter()
          }
          break;
        }
    }
  }

  connectedCallback() {
  }

  get handlers() {
    return {
      'handleCloseClick': this.handleCloseClick
    }
  }

  get contentTmpl(){
    return this.contentTmpls[this.contentId];
  }

  get title() {
    return this.contentTmpl.title;
  }

  get template() {
    return this.contentTmpl.tmpl;
  }

  get templateProperties() {
    return this.contentTmpl.properties;
  }

  get templatePartials() {
    return this.contentTmpl.partials;
  }

  get content() {
    return this.template.render(this.templateProperties, this.templatePartials);
  }

  get contentId() {
    return this.getAttribute('ly-content') || this._contentId;
  }

  mergeData(sources) {
    return Object.assign.apply(Object, [{}].concat(sources));
  }

  get contentTmpls() {
    console.log(urgencyData);
    return {
      'lorem': {
        title: 'Lorem Ipsum',
        tmpl: loremTmpl
      },
      'calendar': {
        title: 'calendar',
        tmpl: calendarTmpl
      },
      'description': {
        title: 'Description',
        tmpl: descriptionTmpl,
        partials: {
          'descriptionViewing': descriptionViewingTmpl
        }
      },
      'description-viewing': {
        title: 'Description',
        tmpl: descriptionViewingTmpl
      },
      'project': {
        title: 'Project',
        tmpl: projectTmpl,
        properties: projectData,
        partials: {
          'virtualTags': virtualTagsPartial
        }
      },
      'tags': {
        title: 'Tags',
        tmpl: tagsTmpl,
        properties: tagsData,
        partials: {
          'virtualTags': virtualTagsPartial
        }
      },
      'due': {
        title: 'Due',
        tmpl: dueTmpl,
        properties: this.mergeData([dueData, synonyms, iso8601]),
        partials: {
          'dateAndTime': dateAndTimePartial,
          'virtualTags': virtualTagsPartial
        }
      },
      'recurrence': {
        title: 'Recurrence',
        tmpl: recurrenceTmpl,
        properties: recurrenceData,
        partials: {
          'virtualTags': virtualTagsPartial
        }
      },
      'status': {
        title: 'Status',
        tmpl: statusTmpl,
        properties: statusData,
        partials: {
          'virtualTags': virtualTagsPartial
        }
      },
      'priority': {
        title: 'Priority',
        tmpl: priorityTmpl,
        properties: priorityData,
        partials: {
          'virtualTags': virtualTagsPartial
        }
      },
      'wait': {
        title: 'Wait',
        tmpl: waitTmpl,
        properties: this.mergeData([waitData, synonyms, iso8601]),
        partials: {
          'dateAndTime': dateAndTimePartial,
          'virtualTags': virtualTagsPartial
        }
      },
      'scheduled': {
        title: 'Scheduled',
        tmpl: scheduledTmpl,
        properties: this.mergeData([scheduledData, synonyms, iso8601]),
        partials: {
          'dateAndTime': dateAndTimePartial,
          'virtualTags': virtualTagsPartial
        }
      },
      'until': {
        title: 'Until',
        tmpl: untilTmpl,
        properties: this.mergeData([untilData, synonyms, iso8601]),
        partials: {
          'dateAndTime': dateAndTimePartial,
          'virtualTags': virtualTagsPartial
        }
      },
      'start': {
        title: 'Start',
        tmpl: startTmpl,
        properties: this.mergeData([startData, synonyms, iso8601]),
        partials: {
          'dateAndTime': dateAndTimePartial,
          'virtualTags': virtualTagsPartial
        }
      },
      'end': {
        title: 'End',
        tmpl: endTmpl,
        properties: this.mergeData([endData, synonyms, iso8601]),
        partials: {
          'dateAndTime': dateAndTimePartial,
          'virtualTags': virtualTagsPartial
        }
      },
      'id': {
        title: 'ID',
        tmpl: idTmpl
      },
      'uuid': {
        title: 'UUID',
        tmpl: uuidTmpl
      },
      'urgency': {
        title: 'Urgency',
        tmpl: urgencyTmpl,
        properties: urgencyData
      },
      'annotations': {
        title: 'Annotations',
        tmpl: annotationsTmpl,
        partials: {
          'descriptionViewing': descriptionViewingTmpl
        }
      },
    }
  }

  afterLeave() {
    this._removeChildNodes();
    this.removeAttribute('ly-open');
  }

  handleCloseClick(e) {
    this.transitions(this.DOM.overlay).leave(this.afterLeave.bind(this));
    this.transitions(this.DOM.slideover).leave(this.afterLeave.bind(this));
  }
}

window.customElements.define('ly-slideover', LySlideover);
