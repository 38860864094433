export default class LyTransitions {

  constructor(element) {
    this._element = element;
  }

  get element(){
    return this._element;
  }

  async enter(beforeEnter) {
    if (!!beforeEnter) {
      beforeEnter();
    }
    this.startTransitionEnter();
    this.startTransitionEnterFrom();

    await this._nextFrame();

    this.stopTransitionEnterFrom();
    this.startTransitionEnterTo();

    await this._afterTransition();

    this.stopTransitionEnter();
  }

  async leave(afterLeave) {

    this.startTransitionLeave();
    this.startTransitionLeaveFrom();

    await this._nextFrame();

    this.stopTransitionLeaveFrom();
    this.startTransitionLeaveTo();

    await this._afterTransition();

    this.stopTransitionLeaveTo();
    this.stopTransitionLeave();
    if (!!afterLeave) {
      afterLeave();
    }
  }

  startTransitionEnter() {
    this._transition('transition-enter', this._start.bind(this));
  }

  stopTransitionEnter() {
    this._transition('transition-enter', this._stop.bind(this));
  }

  startTransitionEnterFrom() {
    this._transition('transition-enter-from', this._start.bind(this));
  }

  stopTransitionEnterFrom() {
    this._transition('transition-enter-from', this._stop.bind(this));
  }

  startTransitionEnterTo() {
    this._transition('transition-enter-to', this._start.bind(this));
  }

  stopTransitionEnterTo() {
    this._transition('transition-enter-to', this._stop.bind(this));
  }


  startTransitionLeave() {
    this._transition('transition-leave', this._start.bind(this));
  }

  stopTransitionLeave() {
    this._transition('transition-leave', this._stop.bind(this));
  }

  startTransitionLeaveFrom() {
    this._transition('transition-leave-from', this._start.bind(this));
  }

  stopTransitionLeaveFrom() {
    this._transition('transition-leave-from', this._stop.bind(this));
  }

  startTransitionLeaveTo() {
    this._transition('transition-leave-to', this._start.bind(this));
  }

  stopTransitionLeaveTo() {
    this._transition('transition-leave-to', this._stop.bind(this));
  }

  _transition(attrName, stageFunction){
    if (this.element.hasAttribute(attrName)) {
      stageFunction(attrName);
    } else {
      console.warn(`transition attribute '${attrName}' not found`)
    }
  }

  _transitionClasses(attrName) {
    return this.element.getAttribute(attrName).split(' ');
  }

  _start(attrName) {
    const classes = this._transitionClasses(attrName)
    this.element.classList.add(...classes);
  }

  _stop(attrName) {
    const classes = this._transitionClasses(attrName)
    this.element.classList.remove(...classes);
  }

  _afterLeave() {
    this._removeChildNodes();
    this.removeAttribute('open');
  }

  _nextFrame() {
    return new Promise(resolve => {
      requestAnimationFrame(() => {
        requestAnimationFrame(resolve);
      });
    });
  }

  _afterTransition() {
    return new Promise(resolve => {
      const duration = Number(
        getComputedStyle(this.element)
        .transitionDuration
        .replace('s', '')
      ) * 1000;

      setTimeout(() => {
        resolve();
      }, duration);
    });
  }

}
