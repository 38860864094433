export default class Session {
  constructor(){
    this._name = process.env.WINGTASK_ENV_NAME;
    if (!!this.localSession) {
      const s = JSON.parse(this.localSession);
      this._email = s.email;
      this._username = s.username;
      this._token = s.token;
    }
  }

  get localSession(){
    const s = localStorage.session;
    switch (s) {
      case undefined:
        return null;
      case 'null':
        return null;
      default:
        return s;
    }
  }

  get email(){
    return this._email || 'not logged in';
  }

  get username(){
    return this._username;
  }

  get token(){
    return this._token;
  }
}
