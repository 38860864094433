export default class Href {
  constructor(href){
    this._href = href || '#';
  }

  toString(){
    return this._href;
  }

  get isExternalLink() {
    return !this.isWingTaskLink;
  }

  get isWingTaskLink(){
    return /wingtask/i.test(this._href);
  }

  get isMailTo(){
    return /mailto/i.test(this._href);
  }
}
