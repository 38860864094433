class LyPassthroughDiv extends HTMLElement {
  constructor(){
    super()
  }

  static get observedAttributes() { return ['ly-selected-tags']; }
  attributeChangedCallback(name, oldValue, newValue) {
    switch (name) {
      case 'ly-selected-tags':
        const tagInput = this.querySelector('ly-tag-input');
        tagInput.setAttribute('ly-selected-tags', newValue);
      break;
    }
  }

}
window.customElements.define('ly-passthrough-div', LyPassthroughDiv);
