import Base from "../ly-base/ly-base.js";
import tmpl from "./template.mustache";
import mobiscroll from "./mobi/js/mobiscroll.javascript.min.js";
import "./mobi/css/ly-calendar.css";
// import './mobi/css/mobiscroll.javascript.scss';

export default class LyCalendar extends Base {
  constructor() {
    super();
    this._dates = "[]";
    this._selectedDate = "";
  }

  connectedCallback() {
    super.connectedCallback();
    this.initMobical();
  }

  initMobical() {
    mobiscroll.eventcalendar(this.containerId, {
      theme: "wingtask-blue",
      display: "inline",
      marked: this.parsedDates,
      onDayChange: this.onDayChange,
      defaultValue: this.selectedDate,
    });
  }

  onDayChange(event, inst) {
    const year = event.date.getFullYear();
    const month = String(event.date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(event.date.getDate()).padStart(2, "0");
    const selectedDate = `${year}-${month}-${day}`;

    this.dispatchEvent(
      new CustomEvent("ly-calendar-day-change", {
        detail: selectedDate,
        bubbles: true,
      }),
    );
  }

  static get observedAttributes() {
    return ["ly-dates"];
  }
  attributeChangedCallback(name, oldValue, newValue) {
    switch (name) {
      case "ly-dates":
        this.initMobical();
        break;
    }
  }

  get containerId() {
    return ["#ly-calendar", this.id].join("-");
  }

  get dates() {
    return this.getAttribute("ly-dates") || this._dates;
  }

  //This doesn't appear to be used where the selectedDate
  //is set to something besides time now
  get selectedDate() {
    const attr = this.getAttribute("selectedDate") || this._selectedDate;
    if (attr === "") {
      return new Date();
    } else {
      return new Date(attr);
    }
  }

  get parsedDates() {
    return JSON.parse(this.dates).map((timeInMs) => {
      const date = new Date(parseInt(timeInMs));
      return { d: date, background: this.colorByDate(date) };
    });
  }

  colorByDate(date) {
    const now = new Date();
    if (this.isDueToday(now, date)) {
      return "var(--secondary-color)";
    }

    if (this.isDue(now, date)) {
      return "var(--accent-color)";
    }

    if (this.isOverdue(now, date)) {
      return "var(--primary-color)";
    }
  }

  isDueToday(now, d2) {
    return this.ymd(now) === this.ymd(d2);
  }

  isDue(now, d2) {
    return this.ymd(now) < this.ymd(d2);
  }

  isOverdue(now, d2) {
    return this.ymd(now) > this.ymd(d2);
  }

  ymd(date) {
    return date.toLocaleString("en-CA").slice(0, 10);
  }

  get tmpl() {
    return tmpl;
  }
}
window.customElements.define("ly-calendar", LyCalendar);
