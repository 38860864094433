import Base from '../ly-base/ly-base.js';
import ymdTmpl from './ymd.mustache';
import localeDateAndTimeTmpl from './locale_date_and_time.mustache';
import timeAgoMicroTmpl from './time_ago_micro.mustache';
import timeUntilMicroTmpl from './time_until_micro.mustache';
import timeAgoTmpl from './time_ago.mustache';
import timeUntilTmpl from './time_until.mustache';

export default class LyDate extends Base {
  constructor(){
    super()
    this._posix = 0;
  }

  get tmpl() {
    return this.dateTmpls[this.format];
  }

  get dateTmpls(){
    return {
      'ymd': ymdTmpl,
      'locale': localeDateAndTimeTmpl,
      'relative-micro': this.relativeMicroTmpl,
      'relative': this.relativeTmpl
    }
  }

  get relativeMicroTmpl(){
    return (this.now > this.posix) ? timeAgoMicroTmpl : timeUntilMicroTmpl;
  }

  get relativeTmpl(){
    return (this.now > this.posix) ? timeAgoTmpl : timeUntilTmpl;
  }

  get now(){
    return new Date().getTime();
  }

  get formatted() {
    return this.posix.format(this.formattingTokens);
  }

  get iso8601() {
    return this.date.toISOString();
  }

  get posix(){
    return parseInt(this.getAttribute('ly-posix')) || this._posix;
  }

  get date(){
    return new Date(this.posix);
  }

  get ymd(){
    return this.date.toLocaleString('en-CA').slice(0, 10);
  }

  get format(){
    return this.getAttribute('ly-format');
  }

  get properties() {
    return {
      ymd: this.ymd,
      iso8601: this.iso8601
    }
  }
}

window.customElements.define('ly-date', LyDate);
