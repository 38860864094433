import ElmInit from './initializers/elm_init.js';

export default class Environment {
  constructor(config){
    this._config = config;
    this._elm = new ElmInit(config);
  }

  get config(){
    return this._config;
  }

  get name(){
    return this._name;
  }

  get session(){
    return (localStorage.session || null);
  }

  init() {
    this._elm.init(this.session);
    this.fixMobileSafariHeight();
  }

  // Mobile Safari is weird about how it calculates 100VH
  // https://dev.to/maciejtrzcinski/100vh-problem-with-ios-safari-3ge9
  fixMobileSafariHeight() {
    const appHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
    window.addEventListener('resize', appHeight)
    appHeight()
  }
}
