import Environment from './environment.js'

export default class Demo extends Environment {
  constructor(config){
    super(config)
    this._name = 'demo';
  }

  get session(){
    return JSON.stringify({
      email: process.env.WINGTASK_ENV_SESSION_EMAIL,
      token: process.env.WINGTASK_ENV_SESSION_TOKEN,
      username: process.env.WINGTASK_ENV_SESSION_USERNAME
    });
  }
}
