import Base from '../ly-base/ly-base.js';
import tmpl from './template.mustache';
import './ly-tag.css';

class LyTag extends Base {

  constructor() {
    super();
  }

  get handlers() {
    return {
      'handleClick': this.handleClick
    }
  }

  get DOM () {
    return {
      button: this.querySelector('button')
    }
  }

  handleClick() {
    this.transitions(this.DOM.button).leave(this.afterLeave.bind(this));
  }

  afterLeave() {
    this.dispatchEvent(
      new CustomEvent(
        'ly-tag-remove-click',
        {detail: this.name, bubbles: true}
      )
    );
  }

  get tmpl() {
    return tmpl;
  }
}

window.customElements.define('ly-tag', LyTag);
